@import "../_config/config";

.article-box {
  position: relative;
  margin-bottom: 30px;
  background-color: #fff;
  background-clip: content-box;
  padding: 0 1.5rem;

  @media only screen and (min-width: 1500px) {
    height: 425px;
  }

  @media (max-width: 1499px) and (min-width: 1340px) {
    height: 415px;
  }

  @media (max-width: 1339px) and (min-width: 1124px) {
    height: 450px;
  }

  @media (max-width: 1123px) and (min-width: 1024px) {
    height: 450px;
  }

  @media (max-width: 1023px) {
    height: auto;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0.5rem;
    height: 1px;
    width: calc(100% - 1rem);
    background-color: $grey-lighter;
  }

  .card {
    margin-top: 0;
    padding: 0;
    height: 100%;
  }

  &.blog-article:before {
    width: calc(100% - 3rem);
    left: 1.5rem;
    @media #{$small-and-down} {
      width: calc(100% - 2rem);
      left: 1rem;
    }
  }

  p {
    line-height: 2rem;
  }

  .thumbnail {
    position: relative;

    max-height: 190px;
    overflow: hidden;

    @media (max-width: 1339px) and (min-width: 1024px) {
      max-height: 270px;
    }
    @media (max-width: 1024px) {
      max-height: initial;
      overflow: initial;
    }

    &:before {
      content: "";
      transition: all 0.2s ease;
      background-color: transparent;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &:hover {
      &:before {
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
      }
      &:after {
        content: "\e951";
        position: absolute;
        font-family: "icomoon";
        color: $white;
        font-size: 6rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
      }
    }

    picture img {
      display: block;
      width: 100%;
      height: 100px;
      max-height: 136px;
      object-fit: cover;
      overflow: hidden;
      position: relative;
      left: 50%;
      transform: translateX(-50%);

      @media (max-width: 1339px) and (min-width: 1024px) {
        max-height: 270px;
      }

      @media (max-width: 1024px) {
        max-height: initial;
        width: 100%;
        height: auto;
        left: initial;
        transform: initial;
      }
    }
  }

  .description,
  .alt-description {
    padding: 1rem;

    a {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
    .sub-heading {
      text-align: left;
      line-height: 2.8rem;
      color: $font-primary;
      padding-bottom: 0;
      &:hover {
        color: $primary-blue;
      }
    }

    .meta-info,
    .meta-info a {
      color: $grey;
      font-size: 1.1rem;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      &[rel="author"] {
        color: $font-primary;
        &:hover {
          color: $primary-blue;
        }
      }
    }
  }

  article > p {
    font-size: 1.3rem;
    color: $grey-darkest;
    padding: 0 2.5rem 0 1rem;

    top: 325px;

    @media (max-width: 1339px) {
      top: 0;
      position: relative;
      padding: 0 1rem;
    }
  }

  .read-more {
    font-size: 1.3rem;

    position: absolute;
    bottom: 10px;
    right: 25px;
    border-radius: 15px;

    @media (max-width: 1024px) {
      position: relative;
      margin-bottom: 10px;
      margin-right: 10px;
      bottom: 0;
      right: 0;
    }
  }
}
