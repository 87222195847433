.nobold {
  font-weight: normal;
}
strong,
.strong,
.bold {
  font-weight: bold;
}

.hidden {
  display: none;
}
// wordpress use bootstrap and hidden has a display: none !important;
// .... so I created a sculpteo hidden class
.s_hidden {
  display: none;
}

.center,
.acenter {
  text-align: center !important;
}
.left,
.aleft {
  text-align: left !important;
}
.right,
.aright {
  text-align: right !important;
}
.atop {
  vertical-align: top;
}
.amiddle {
  vertical-align: middle;
}
.abottom {
  vertical-align: bottom;
}
.fleft {
  float: left !important;
}
.fright {
  float: right !important;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.clearfix {
  zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}

.clr,
.clear,
.clears {
  clear: both;
}
.sm-clr {
  display: none;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.block {
  display: block;
}
.inline-table {
  display: inline-table;
}
.bound {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.bound-x {
  left: 0;
  right: 0;
}
.bound-8 {
  top: 8px;
  bottom: 8px;
  left: 8px;
  right: 8px;
}
.abs-bottom {
  bottom: 0;
}
.abs-right {
  right: 0;
}
.abs-top {
  top: 0;
}
.mauto {
  margin: 0 auto;
}
.wide {
  width: 100% !important;
}
.embedded-wide {
  // Fix for embedded cart
  width: 100% !important;
}
.embedded-content {
  width: 980px !important;
  padding: 0 !important;
}
.high {
  height: 100% !important;
}
.no-margin {
  margin: 0 !important;
}
.no-padding {
  padding: 0 !important;
}

.w180 {
  width: 180px;
}

.w240 {
  width: 240px;
}
.w320 {
  width: 320px;
}
.w480 {
  width: 480px;
}
.w640 {
  width: 640px;
}
.w720 {
  width: 720px;
}
.m2t {
  margin-top: 2px !important;
}
.m4 {
  margin: 4px !important;
}
.m4t {
  margin-top: 4px !important;
}
.m4b {
  margin-bottom: 4px !important;
}
.m4r {
  margin-right: 4px !important;
}
.m4l {
  margin-left: 4px !important;
}
.m8t {
  margin-top: 8px !important;
}
.m8b {
  margin-bottom: 8px !important;
}
.m8l {
  margin-left: 8px !important;
}
.m8r {
  margin-right: 8px !important;
}
.m16 {
  margin: 16px !important;
}
.m16t {
  margin-top: 16px !important;
}
.m16b {
  margin-bottom: 16px !important;
}
.m16l {
  margin-left: 16px !important;
}
.m16r {
  margin-right: 16px !important;
}
.m24 {
  margin: 24px !important;
}
.m24t {
  margin-top: 24px !important;
}
.m24b {
  margin-bottom: 24px !important;
}
.m24l {
  margin-left: 24px !important;
}
.m24r {
  margin-right: 24px !important;
}
.m28r {
  margin-right: 28px !important;
}
.m48l {
  margin-left: 48px !important;
}
.m48r {
  margin-right: 48px !important;
}
.p4 {
  padding: 4px;
}
.p4b {
  padding-bottom: 4px;
}
.p4t {
  padding-top: 4px;
}
.p4l {
  padding-left: 4px;
}
.p4r {
  padding-right: 4px;
}
.p6 {
  padding: 6px;
}
.p6l {
  padding-left: 6px;
}
.p6r {
  padding-right: 6px;
}
.p8 {
  padding: 8px;
}
.p8l {
  padding-left: 8px;
}
.p8r {
  padding-right: 8px;
}
.p8t {
  padding-top: 8px;
}
.p8b {
  padding-bottom: 8px;
}
.p10 {
  padding: 10px;
}
.p10l {
  padding-left: 10px;
}
.p10r {
  padding-right: 10px;
}
.p16 {
  padding: 16px;
}
.p16t {
  padding-top: 16px;
}
.p16b {
  padding-bottom: 16px;
}
.p16l {
  padding-left: 16px;
}
.p16r {
  padding-right: 16px;
}
.p24 {
  padding: 24px;
}
.p24t {
  padding-top: 24px;
}
.p24b {
  padding-bottom: 24px;
}
.p24l {
  padding-left: 24px;
}
.p24r {
  padding-right: 24px;
}
.p32l {
  padding-left: 32px;
}
.p48l {
  padding-left: 48px;
}
.h50 {
  height: 50px;
}
.no-border {
  border: 0;
}
.border-dotted-left {
  border-left: 1px dotted #aaa;
}
.border-dotted-right {
  border-right: 1px dotted #aaa;
}
.border-dotted-top {
  border-top: 1px dotted #aaa;
}
.border-dotted-bottom {
  border-bottom: 1px dotted #aaa;
}
.border-dotted {
  border: 1px dotted #aaa !important;
}
.border-solid-left {
  border-left: 1px solid #888 !important;
}
.border-solid-right {
  border-right: 1px solid #888 !important;
}
.border-solid-top {
  border-top: 1px solid #888 !important;
}
.border-solid-bottom {
  border-bottom: 1px solid #888 !important;
}
.border-solid {
  border: 1px solid #888 !important;
}
.border-white {
  border-color: white !important;
}

.border-red {
  border-color: red !important;
}

.border-full {
  border: 1px solid #888;
  border-top-style: inset;
  border-right-style: inset;
  border-bottom-style: inset;
  border-left-style: inset;
}
.border-full td,
.border-full tr,
.border-full th {
  border: 1px solid #888;
  border-top-style: inset;
  border-right-style: inset;
  border-bottom-style: inset;
  border-left-style: inset;
}
.max-round {
  border-radius: 7px;
}
.round {
  border-radius: 3px;
}
.round-tl {
  border-top-left-radius: 3px;
}
.round-tr {
  border-top-right-radius: 3px;
}
.round-bl {
  border-bottom-left-radius: 3px;
}
.round-br {
  border-bottom-right-radius: 3px;
}

.bgtransparent {
  background-image: none;
  background-color: transparent !important;
}
.white {
  color: white !important;
}
.black {
  color: black !important;
}
.gray {
  color: #aaa;
}
.lightgray {
  color: #ccc;
}
.bgwhite {
  background-image: none;
  background-color: white !important;
}

.bggrey {
  background-image: none;
  background-color: #ccc !important;
}
.bglightgrey {
  background-image: none;
  background-color: #eee !important;
}
.bgblack {
  background-image: none;
  background-color: black !important;
}

.bggreen {
  background-image: none;
  background-color: #d7f9be !important;
}

.bgblue {
  background-image: none;
  background-color: #1c85e0 !important;
}

.nowrap {
  white-space: nowrap;
}
.cleargray {
  color: #ccc;
}
.red {
  color: red;
}
.orange {
  color: orange;
}
.green {
  color: green;
}
.medical-green {
  color: $medical-green-manage;
}
.test-purple {
  color: $test-purple-manage;
}
.bg-soft-gray {
  background-color: #ddd;
}
.outline {
  outline: 1px red solid;
}
.size14 {
  font-size: 14px !important;
}

.overauto {
  overflow: auto;
}
