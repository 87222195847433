.list {
  position: relative;
  display: block;
  list-style-type: none;
  padding-bottom: 20px;
}

.list--item,
.list li {
  position: relative;
  font-size: 1.4rem;
  margin-left: 10px;
  padding-bottom: 10px;
  text-align: left;
}

.list--item:before,
.list li:before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  background: $primary-blue;
  height: 4px;
  width: 4px;
  top: 8px;
  left: -10px;
  margin: auto;
  transition: background 0.2s linear;
}
