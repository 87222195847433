.loading-circle {
  &:before {
    position: absolute;
    top: 50%;
    margin-top: -12px;
    content: "";
    display: inline-block;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    animation: spinner 0.6s linear infinite;
    clear: both;
    border: 2px solid $primary-blue;
    border-top-color: transparent;
    z-index: 1;
  }

  &.loading-circle-anti:before {
    animation: spinner-anti 0.6s linear infinite;
  }

  &.loading-circle--white:before {
    border: 2px solid #fff;
    border-top-color: transparent;
  }

  &.loading-circle--center {
    &:before {
      left: 50%;
      margin-left: -12px;
    }
    &.loading-circle--small:before {
      margin-left: -8px;
    }
  }

  &.loading-circle--small:before {
    margin-top: -8px;
    width: 16px;
    height: 16px;
  }

  &.loading-circle--medium:before {
    top: 4px;
    margin-left: -35px;
    width: 20px;
    height: 20px;
  }

  &.loading-circle--big:before {
    width: 60px;
    height: 60px;
    margin-left: -30px;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner-anti {
  to {
    transform: rotate(-360deg);
  }
}
