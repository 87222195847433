#header.header-desktop {
  height: 70px;
  line-height: initial;

  @media print {
    display: none;
  }

  #header-outside-click-overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 849; // just below the header-wrapper
    display: none;
    // background-color:aqua; opacity: 0.5;  // debug
  }

  //.nav-submenu-close-button {
  //	display: inline;
  //	color:white;
  //	float:right;
  //	margin-top: -30px;
  //}

  #header-wrapper {
    position: fixed;
    height: inherit;
    top: 0;
    width: 100%;
    z-index: 850;

    background-color: $white;
    color: $font-primary;
    font-family: $secondary-fontfamily;
    font-size: 2.2rem;
    font-weight: bold;
    box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);

    // change the shadow and add a colored tab when user is connected

    $color-map: (
      staff: $staff-color,
      superuser: $superuser-color,
      business-rewards: $business-rewards-color,
    );
    @each $name, $color in $color-map {
      &.header-special-user-#{$name} {
        $role-color: $color;
        box-shadow: 0px 2px 6px 1px $role-color;

        .role-tab {
          $width: 50px;
          $diamond-width: 20px;

          font-family: $secondary-fontfamily;
          font-weight: bold;
          background-color: $role-color;
          font-size: 12px;
          text-align: center;
          width: $width;

          // we are going to position the element under the username
          // glued to the bottom of the userbar
          position: absolute;
          bottom: 0px;
          // we flush it a bit to the left to compensate
          // magic value depending of the width (works well with 50px)
          // => there might be an better way of doing it
          left: 30%;

          // add diamond shape blocks on each sides to make old-chrome
          // like tab
          &::before,
          &::after {
            background-color: $role-color;
            display: block;
            content: " ";
            position: absolute;
            top: 0;
            height: 100%;
            width: $diamond-width;
          }
          &::before {
            left: calc(-1 * $diamond-width/2);
            transform: skew(-25deg, 0deg);
          }
          &::after {
            right: calc(-1 * $diamond-width/2);
            transform: skew(25deg, 0deg);
          }

          &.business-rewards,
          &.superuser {
            width: 110px;
            left: 14%;
            color: white;
          }
          &.cart-name {
            flex-grow: 2;
            position: relative;
            left: 0;
          }
          &.cart-name-superuser {
            color: white;
          }
        }
      }
    }
  }

  .cart-bar {
    display: flex;
    position: absolute;
    bottom: 0px;
    width: 100%;
  }

  .cart-bar.hidden {
    display: none;
  }

  .cart-bumper {
    flex-grow: 1;
  }

  #navbar {
    position: relative;
    height: 100%;

    padding: 0 40px;

    @media only screen and (max-width : #{$large-screen}) {
      padding: 0 20px;
    }
  }

  #nav-main {
    height: 100%;
    position: relative;
  }

  .nav-wrapper {
    max-width: 1380px;
    margin: 0 auto;
    display: flex;
  }

  .nav-flexdefault {
    position: relative;
    align-items: center;
    display: flex;
    text-align: left;
    white-space: nowrap;
    justify-content: flex-start;
  }

  #nav-logo {
    margin-right: 40px;

    .sculpteo-logo {
      width: 200px;
      height: 90px;
      max-width: initial;
      display: flex;
    }

    @media only screen and (max-width: 1300px) {
      margin-right: 20px;
    }

    @media only screen and (max-width: 1240px) {
      width: 30px;
      overflow: hidden;
      margin-right: 10px;
      .sculpteo-logo {
        width: 148px;
        /* clip logo basf subtitle keeping only the icon */
        clip-path: circle(14.68% at 16px 38px);
        margin-top: 10px;
      }
    }
  }

  #nav-logo-pro {
    font-size: 1.6rem;
    position: absolute;
    color: $black;
    font-family: $primary-fontfamily;
    font-weight: bold;
    margin-left: 206px;
    bottom: 27px;

    @media only screen and (max-width: 1300px) {
      margin-left: 202px;
      bottom: 27px;
    }

    @media only screen and (max-width: 1240px) {
      margin-left: 0;
      bottom: 0;
    }
  }

  #nav-links {
    display: flex;
    .head-link {
      font-family: $secondary-fontfamily;
      font-size: 2.2rem;
      font-weight: bold;
    }
  }

  .nav-link-hat {
    font-family: $secondary-fontfamily;
    font-weight: bold;

    font-size: 1.3rem;
    color: $font-secondary;
    position: absolute;
    top: 7px;
  }
  .nav-link {
    background: none;
    border: none;
    color: $font-primary;
    padding-left: 20px;
    padding-right: 20px;

    @media only screen and (max-width: 1300px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    &:hover {
      color: $primary;
    }

    &[data-nav-link]:not(.nav-user):after {
      content: "";

      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      padding-top: 40px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $font-primary;

      opacity: 0;
    }

    &[data-nav-link]:hover {
      text-decoration: none;
    }

    &[data-nav-link]:focus {
      text-decoration: none;
    }

    &[data-nav-link] {
      cursor: default;
    }

    &.active {
      color: $primary;
    }

    &[data-nav-link].active:after {
      opacity: 1;
    }
  }

  .nav-bloc {
    flex-shrink: 1;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
    display: flex;
    width: 100%;
  }

  .nav-bloc-right {
    justify-content: flex-end;
  }

  .menu_login_icon {
    @media only screen and (max-width: 600px) {
      display: none;
    }
    height: 24px;
    padding-left: 10px;
  }

  .nav-login {
    display: flex;
    align-items: center;
    padding-right: 40px;
    color: $primary;
    text-decoration: none;

    &:hover {
      opacity: 0.7;
      filter: alpha(opacity=70);
    }
  }

  .nav-signup {
    padding: 3px 36px;
    background-color: $primary;
    border-radius: 3px;
    color: $white;
    box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.25);

    text-decoration: none;

    &:hover {
      color: $white;
      background-color: $primary-light;
      text-decoration: none;
    }
  }

  .nav-bloc-right {
    .nav-shopping,
    .nav-upload,
    .nav-models,
    .nav-user {
      text-decoration: none;
    }
  }

  .nav-link-icon {
    font-size: 2rem;
  }

  .nav-upload {
    display: flex;
    align-items: center;
    color: $primary;
    .nav-link-icon {
      padding-left: 10px;
    }

    &:hover {
      color: $secondary;
    }

    @media only screen and (max-width: 1240px) {
      span:not(.nav-link-icon) {
        display: none;
      }
    }
  }

  .nav-user {
    .nav-link-icon {
      padding-left: 10px;
      position: relative;
      bottom: 3px;
    }

    .nav-user-screen-name {
      font-size: 2.2rem;
      font-family: $secondary-fontfamily;
      font-weight: bold;
      position: relative;
      max-width: 120px;
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      top: 2px;
    }
  }

  .nav-shopping {
    .nav-shopping-badge {
      position: absolute;
      top: 7px;
      right: 3px;
      background-color: $grey-light;
      color: $white;
      font-size: 1.4rem;
      text-align: center;
      padding: 3px;
      min-width: 25px;
      border-radius: 200px;
      font-family: $primary-fontfamily;

      transition: background-color 0.5s ease;

      &.badge-active {
        background-color: $soft-red;
      }
    }
  }

  // YUICompressor removes the % after a 0
  // 0% --> 0
  // And so it is not read by the browser
  // to prevent that, we use 1%
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 0;
    }
    25% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  // **************************
  // **************************
  // Submenu
  // **************************
  // **************************

  .nav-submenu {
    width: 100%;
    background-color: $font-primary;
    text-align: left;
    padding: 40px;

    border-bottom: 2px solid darken($font-primary, 2%);

    .nav-wrapper {
      position: relative;
      justify-content: space-between;
    }
  }

  // allow scrolling and reduce padding on small screens
  @media (max-width: $large-screen) {
    .nav-submenu[data-nav-submenu="nav-submenu-material"] {
      height: calc(100vh - 68px); // 70px is the height of the header
      overflow-y: scroll;
      padding: 16px;
    }
  }

  [data-nav-submenu] {
    display: none;
    opacity: 0;

    &.visible.anim {
      animation-duration: 0.2s;
      animation-name: fade-in;
      animation-fill-mode: both;
      animation-timing-function: ease;
      display: block;
    }

    &.visible {
      display: block;
      opacity: 1;
    }
  }

  .nav-submenu-bloc {
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
  }

  // TODO: remove when not used anymore in the basf theme (old non flex menu)
  .nav-submenu-material-offset {
    position: relative;
    top: 29px;
  }

  .nav-submenu-learn-offset {
    position: relative;
    top: 43px;
  }

  .nav-submenu-link {
    display: block;
    color: $font-secondary-darkbackground;
    margin: 7px 0;

    font-family: $primary-fontfamily;
    font-size: 1.4rem;
    font-weight: 400;

    &:hover {
      color: white;
    }
  }

  .nav-submenu-title {
    display: block;
    font-family: $secondary-fontfamily;
    color: $accent;
    margin-bottom: 20px;
    position: relative;
    font-size: 2rem;

    &:hover {
      color: $accent-light;
      text-decoration: underline;
    }

    &.nav-submenu-title-space {
      margin-top: 40px;
    }

    .nav-submenu-title-icon {
      font-size: 18px;
      margin-left: 8px;
      position: absolute;
      top: 50%;
      transform: translateY(-39%);
      transition: transform 0.3s ease;
    }

    &:hover .nav-submenu-title-icon {
      transform: translateY(-39%) translateX(3px);
    }
  }

  .nav-submenu-title-nolink {
    display: block;
    font-family: $secondary-fontfamily;
    color: $accent;
    margin-bottom: 20px;
    position: relative;
    font-size: 2rem;
  }

  .nav-submenu-title-technology {
    display: block;
    font-family: $secondary-fontfamily;
    color: $white;

    font-size: 1.8rem;
    margin-top: 20px;

    &:hover {
      color: $grey-light;
    }
  }

  .nav-submenu-button {
    position: relative;
    display: inline-block;
    margin-top: 40px;
    padding: 3px 36px;
    background-color: $accent;
    border-radius: 3px;
    color: $font-primary;
    box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.2);
    text-decoration: none;

    &:hover {
      color: $font-primary;
      background-color: $accent-light;
      text-decoration: none;
    }
  }

  // **************************
  // **************************
  // Submenu (user logged in)
  // **************************
  // **************************

  [data-nav-submenu="nav-submenu-user"] {
    display: none;

    position: absolute;
    top: 55px;
    left: -20px;
    background-color: $white;
    border-radius: 4px;

    z-index: 99;

    box-shadow: 0 6px 6px 3px rgba(0, 0, 0, 0.2);
  }

  .nav-user-submenu-bloc {
    padding: 20px 40px;
  }

  .nav-user-submenu-link {
    display: block;

    color: $font-primary;
    font-size: 2rem;

    .nav-link-icon {
      padding-right: 20px;
      color: $font-disabled;
      font-size: 2.2rem;
      position: relative;
      top: 2px;
    }

    &:hover {
      text-decoration: none;
      color: $primary;
      .nav-link-icon {
        color: $primary;
      }
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .nav-user-dashboard {
    padding-bottom: 20px;
    position: relative;

    .nav-link-icon {
      color: $font-secondary;
    }

    &:before {
      position: absolute;
      content: "";

      width: calc(100% + 80px);
      height: calc(100% + 20px);

      left: -40px;
      top: -20px;
      background-color: $grey-lightest;

      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      z-index: 0;
    }
  }

  .nav-user-event-badge {
    position: absolute;
    top: -10px;
    left: 13px;
    background-color: $accent;
    color: $white;
    font-size: 1.2rem;
    text-align: center;
    padding: 3px;
    min-width: 25px;
    border-radius: 200px;
    font-family: $primary-fontfamily;

    display: none;

    &.nav-user-event-badge-visible {
      display: block;
    }

    &.nav-user-event-badge-critical {
      background-color: $soft-red;
    }
  }

  .nav-user-logout {
    position: relative;
    padding-top: 20px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% + 40px);
      height: 1px;
      top: 0;
      left: -20px;
      background-color: $grey-lightest;
    }
  }
}

// fix the tilt when removing the scroll bar after opening one of the selectors
body.no-scroll {
  #nav-main {
    transform: translateX(-7.5px); //half of 15px (size of the scrollbar)
  }
}
