@import "../_config/config";

.pagination {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  li {
    display: inline-block;
    height: 30px;
    margin: 5px;
    padding-top: 5px;
    border: 1px solid $grey-light;
    border-radius: 2px;
    transition: all 0.5s ease;
    flex-basis: 4%;

    a {
      color: $font-primary;
      border: none;

      &:hover {
        color: $font-primary;
        background-color: transparent;
      }
    }

    &.current {
      background: $secondary-blue;
      border-color: $secondary-blue;
      a {
        color: $white;
        font-weight: bold;
        pointer-events: none;
        cursor: default;
      }
    }
  }
}

.pagination--end {
  justify-content: flex-end;
}

.pagination-total-per-page {
  text-align: left;

  form {
    display: inline-block;
  }

  select {
    height: 30px;
    margin-top: 5px;
  }

  span {
    margin-left: 10px;
  }
}

.disabled .pagination,
.pagination.disabled {
  li {
    background: $grey-light;
    border-color: $grey-light;
    &.current {
      background: $grey;
      border-color: $grey;
    }
  }
}
