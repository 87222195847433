.breadcrumb {
  margin-top: 10px;
  list-style: none;
  text-align: left;
  @media #{$small-and-down} {
    margin-left: 10px;
  }
}

.breadcrumb li {
  margin: 0;
  display: inline-block;
  color: inherit;
  a {
    color: $font-primary;
    text-decoration: none;
    &:hover {
      color: $primary-blue;
    }
  }
}

.breadcrumb li:not(:last-child):after {
  content: ">";
  display: inline-block;
  margin: 0 8px;
}

.breadcrumb li:nth-last-child(2):after {
  color: $grey;
}

.breadcrumb li:last-child {
  color: $grey;
}
