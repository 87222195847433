.testimonial {
  position: relative;
}

.testimonial-header {
  position: relative;
  text-align: left;
  margin-bottom: 10px;

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.testimonial-picture {
  position: relative;
  width: 50px;
  height: 50px;
  float: left;

  img {
    position: relative;
    max-width: 100%;
    height: auto;

    border-radius: 50%;
  }
}

.testimonial-info {
  position: relative;
  margin-left: 60px;

  min-height: 50px;

  border-bottom: 1px solid $primary-blue;
}

.testimonial-name {
  position: relative;
  font-size: 1.6rem;
  color: $primary-blue;
}

.testimonial-job {
  position: relative;
  font-size: 1.3rem;
  color: $grey;
}

.testimonial-content {
  position: relative;
  text-align: left;
  font-size: 1.4rem;
}
