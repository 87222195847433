button {
  font-family: $primary-fontfamily;
}

//basic button
.btn,
.s_btn {
  position: relative;
  display: inline-block;
  float: none;

  background-color: $primary-blue;
  background-image: none;

  width: auto;
  height: auto;

  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
  color: #fff;
  text-shadow: none;

  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 20px;
  padding-left: 20px;

  margin: 0;

  border-radius: 2px;
  border: none;
  transition: all 0.2s ease;

  cursor: pointer;
  pointer-events: all;

  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.3);

  &:hover {
    border: none;
    background-color: $primary-blue-lighter;
    color: #fff;
    text-decoration: none;
  }

  &.open {
    border: none;
    background-color: $primary-blue-lighter;
    color: #fff;
    text-decoration: none;
  }
  &.accordion:after {
    content: "+";
    float: right;
    margin-right: 5px;
  }
  &.accordion.open:after {
    content: "-";
  }
}

div.accordion {
  padding: 5px;
  background-color: $grey-white;
}

//call to action button, use mainly on the home/service page
.btn--cta,
.s_btn--cta {
  font-weight: bold;
  border-radius: 3px;
  font-size: 2rem;

  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 40px;
  padding-left: 40px;

  box-shadow: 0 5px 4px -2px rgba(0, 0, 0, 0.3);

  //make it bigger on big screen
  @media #{$medium-and-up} {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 60px;
    padding-left: 60px;
  }

  &:hover {
    box-shadow: 0 8px 4px -2px rgba(0, 0, 0, 0.2);
    transform: translateY(-1px);
  }
}

//make it taller or wider
.btn--taller {
  padding-top: 10px;
  padding-bottom: 10px;
}

.btn--smaller {
  padding-top: 0;
  padding-bottom: 0;
}

.btn--wider {
  padding-right: 30px;
  padding-left: 30px;
}

.btn--narrower {
  padding-right: 10px;
  padding-left: 10px;
}

.btn--narrowest {
  padding-right: 0;
  padding-left: 0;
}

//set the font size of the button (will, of course, increase the size of the button)
.btn--xxl {
  font-size: 1.8rem;
}

.btn--xl {
  font-size: 1.6rem;
}

.btn--xs {
  font-size: 1.3rem;
}

//if you neet a button that take all the place available
.btn--fullwidth {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.btn--fullwidth-limited {
  width: 100%;
  max-width: 180px;
  padding-left: 0;
  padding-right: 0;
}

.btn--fullwidth-limited-widder {
  width: 100%;
  max-width: 380px;
  padding-left: 0;
  padding-right: 0;
}

//set some margin
.btn--mb {
  margin-bottom: $padding-size-small;
}

.btn--ml {
  margin-left: $padding-size-small;
}

.btn--mt {
  margin-top: $padding-size-small;
}

.btn--mr {
  margin-right: $padding-size-small;
}

// icons

.btn--icon-right {
  [class^="icon-"],
  [class*=" icon-"] {
    margin-left: 10px;
  }
}

//set shadow
.btn--shadow {
  box-shadow: 0 5px 4px -2px rgba(0, 0, 0, 0.3);

  &:hover {
    box-shadow: 0 8px 4px -2px rgba(0, 0, 0, 0.2);
    transform: translateY(-1px);
  }
}

.btn--darkblue {
  background-color: $dark-blue;
  color: #ffffff;
  &:hover {
    color: #ffffff;
    background-color: $dark-blue-lighter;
  }
}

.btn--border {
  color: $primary-blue;
  background-color: transparent;
  border: 1px solid $primary-blue;

  box-shadow: none;

  &:hover,
  &.btn--border--active {
    color: #ffffff;
    background-color: $primary-blue;
    border: 1px solid $primary-blue;

    box-shadow: none;
    transform: none;
  }
}

.btn--border-white {
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff;

  box-shadow: none;

  &:hover {
    color: $primary-blue;
    background-color: #ffffff;
    border: 1px solid #ffffff;

    box-shadow: none;
    transform: none;
  }
}

.btn--border-black {
  color: $font-primary;
  background-color: transparent;
  border: 1px solid $black;

  box-shadow: none;

  &:hover {
    color: $white;
    background-color: $font-primary;
    border: 1px solid $black;

    box-shadow: none;
    transform: none;
  }
}

.btn--border-db {
  color: $dark-blue;
  background-color: transparent;
  border: 1px solid $dark-blue;

  box-shadow: none;

  &:hover {
    color: #fff;
    background-color: $dark-blue;
    border: 1px solid $dark-blue;

    box-shadow: none;
    transform: none;
  }
}

.btn--border-red {
  color: $error-red;
  background-color: transparent;
  border: 1px solid $error-red;

  box-shadow: none;

  &:hover {
    color: #fff;
    background-color: $error-red;
    border: 1px solid $error-red;

    box-shadow: none;
    transform: none;
  }
}

.btn--border-text-b {
  @extend .btn--border-white;
  &:hover {
    color: $font-primary;
    background-color: #fff;
    border: 1px solid #fff;
  }
}

.btn--border-text-db {
  @extend .btn--border-white;
  &:hover {
    color: $dark-blue;
    background-color: #fff;
    border: 1px solid #fff;
  }
}

.btn--white {
  background-color: #ffffff;
  color: $font-primary;
  border: 1px solid #fff;
  &:hover {
    color: $dark-blue;
    background-color: $grey-lightest;
    border: 1px solid #fff;
  }
}

.btn--red {
  background-color: $error-red;
  color: $white;
  &:hover {
    background-color: darken($error-red, 10%);
    color: $white;
  }
}

.btn--updating,
.btn:disabled,
.updating .btn,
.disabled .btn,
.btn.disabled,
.btn[disabled="disabled"] {
  cursor: default;
  background-color: $grey-light;
  border: none;
  color: #fff;
  box-shadow: none;

  &:hover {
    background-color: $grey-light;
    border: none;
    box-shadow: none;
  }

  &.btn--border-white,
  &.btn--border-black,
  &.btn--border-db,
  &.btn--border-text-b,
  &.btn--border-text-db {
    border: 1px solid $grey-light;
    color: $grey-light;
    background-color: transparent;
    &:hover {
      border: 1px solid $grey-light;
      color: $grey-light;
      background-color: transparent;
    }
  }
}

//text link
.text-link {
  font-size: 1.4rem;
  color: $primary-blue;
  text-decoration: none;
  &:hover {
    color: $primary-blue-darker;
    text-decoration: none;
  }

  &.text-link--underline:hover {
    text-decoration: underline;
  }
}

.text-link--sm {
  font-size: 1.3rem;
}

.text-link--delete {
  color: $grey;
  &:hover {
    color: $error-red;
  }
}

.text-link--delete-important {
  color: $error-red;
  &:hover {
    color: darken($error-red, 10%);
  }
}

.text-link--cancel {
  color: $grey;
  &:hover {
    color: $grey-darkest;
  }
}

.text-link--secondary {
  color: $dark-blue;
  &:hover {
    color: $dark-blue-darker;
  }
}

.text-link--updating,
.text-link--disabled,
.updating .text-link,
.disabled .text-link {
  cursor: default;
  color: $grey-light;
  &:hover {
    color: $grey-light;
  }
}

a.disabled {
  pointer-events: none;
  cursor: default;
}
