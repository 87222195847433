$countdown-font-size: 2rem;

.countdown-container {
  display: flex;
  align-items: center;
  font-weight: 400; // this wouldn't be needed if we used _reset on cms pages (WD-4015)

  .countdown-text {
    padding: 0 4px;
  }

  ul {
    margin: 0; // this wouldn't be needed if we used _reset on cms pages (WD-4015)
    padding-inline-start: 0px; // Remove default padding
  }

  li {
    display: inline-block;
    font-size: 0.4 * $countdown-font-size;
    line-height: initial; // this wouldn't be needed if we used _reset on cms pages (WD-4015)
    list-style-type: none;
    padding: 0 2px;
    text-transform: uppercase;
    text-align: center; // this wouldn't be needed if we used _reset on cms pages (WD-4015)
  }

  li span {
    display: block;
    font-size: $countdown-font-size;
    line-height: $countdown-font-size;
  }

  .countdown-over {
    display: none;
  }

  @media all and (max-width: 1280px) {
    display: none;
  }

  &.disabled {
    display: none;
  }
}
