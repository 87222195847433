.forms {
  position: relative;
  width: 100%;
  margin: 0 auto;

  &.forms--limited-width {
    @media #{$medium-and-up} {
      max-width: 50%;
    }
  }
}

.forms * {
  box-sizing: border-box !important; //needed for radio, checkbox and more
}

.forms label {
  display: block;
  color: $primary-blue;
  padding-bottom: 5px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  font-weight: 400;
  margin-bottom: 15px;
  text-align: left;
}

.forms label.label--no-margin {
  margin-bottom: 0;
}

.forms label.label--black {
  color: $font-primary;
}
.forms label.label--grey {
  color: $grey;
}

.forms .input-label {
  position: relative;
  display: block;
  text-align: left;
  padding-bottom: 4px;
  font-size: 1.4rem;
}

.forms .input-label--black {
  color: $font-primary;
}

.forms .input-label:first-letter {
  text-transform: uppercase;
}

.forms label.align-right {
  text-align: right;
}

.forms input,
.forms .form-control {
  position: relative;
  display: block;
  width: 100%;
  height: 28px;
  border: none;
  background-color: $grey-lightest;
  background-image: none !important;
  margin-bottom: 0;
  color: $font-primary;
  padding: 0 0 0 10px;
  box-shadow: none;
  outline: none;
  border-radius: 0;
  font-size: 14px;
  font-family: $primary-fontfamily;
}

.forms .show_password {
  display: block;
  position: absolute;
  right: 15px;
  bottom: 12px;
  font-size: 18px;
  color: $grey;
  cursor: pointer;
}

.forms input:focus {
  background-color: $grey-lightest;
}

.forms .no-margin-top {
  margin-top: 0;
}

.forms input[type="file"] {
  background: transparent;
  padding: 0;
}

.forms .line:after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background: $primary-blue;
  position: absolute;
  z-index: 1;
  transition: all 0.2s;
}

.forms input:focus ~ .line:after {
  width: 100%;
}

.forms .line {
  display: block;
  width: 100%;
  height: 1px;
  background: $grey-light;
  position: absolute;
  transition: all 0.2s;
}

.forms input.isUnvalid ~ .line:after,
.forms select.isUnvalid ~ .line:after {
  width: 100%;
  height: 2px;
  background-color: $error-red;
}
.forms input.isUnvalid ~ .line:before,
.forms select.isUnvalid ~ .line:before {
  content: "!";
  position: absolute;
  top: -28px;
  left: -12px;
  font-size: 20px;
  color: $error-red;
}

.forms input.isValid ~ .line:after {
  width: 100%;
}

.forms input[type="checkbox"] ~ .line {
  background: transparent;
  width: calc(100% - 40px);
}
.forms input[type="checkbox"].isValid ~ .line:after {
  width: 0;
}
.forms input[type="checkbox"].isUnvalid ~ .line:after {
  width: 100%;
}

.forms .field-icon {
  position: absolute;
  padding-left: 6px;
  font-size: 18px;
  padding-top: 2px;
  color: $grey;
  pointer-events: none;
  left: 0;
  z-index: 2;
}

.forms .input_hasIcon input {
  padding-left: 30px;
}

.forms textarea {
  position: relative;
  display: block;
  font-family: inherit;
  width: 100%;
  border: 1px solid transparent;
  background-color: $grey-lightest;
  resize: none;
  font-size: 14px;
  padding: 10px;
  border-radius: 0;
}
.forms textarea:hover {
  border-color: $grey-lighter;
}
.forms textarea:focus {
  border-color: $primary-blue;
}

.forms select {
  position: relative;
  display: block;
  margin-top: 10px;
  height: 30px;
  width: 100%;
  border: 1px solid $grey-lighter;
  border-bottom: 1px solid $grey-light;
  background-color: $grey-lightest;
  box-shadow: none;
  font-family: $primary-fontfamily;
  font-size: 1.4rem;
}
.forms select:hover {
  border-color: $grey-light;
}
.forms select:focus {
  border-color: $primary-blue;
}

.forms select.select--auto {
  width: auto;
  display: inline-block;
}

.forms select.select--margin-left {
  margin-left: 10px;
}

.forms select.select--no-margin-top,
.forms .select--no-margin-top select {
  margin-top: 0;
}

.disabled .forms select,
.forms.disabled select,
.forms select.disabled {
  color: $grey;
  background-color: $white;
}

.forms .checkbox {
  font-size: 14px;
  text-align: left;
}
.forms .checkbox ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.forms .checkbox ul li {
  margin-bottom: 12px;
}

.forms .checkbox ul li.no-margin-bottom {
  margin-bottom: 0;
}

.forms [type="checkbox"] {
  position: absolute;
  left: -9999px;
}

.forms [type="checkbox"]:not(:checked) + label,
.forms [type="checkbox"]:checked + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.forms [type="checkbox"]:not(:checked) + label.checkbox-right,
.forms [type="checkbox"]:checked + label.checkbox-right {
  padding-left: 0;
  padding-bottom: 0;
  padding-right: 25px;
  display: block;
}

/* checkbox aspect */
.forms [type="checkbox"]:not(:checked) + label:before,
.forms [type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 15px;
  height: 15px;
  border: 1px solid $primary-blue;
  background: #fff;
  border-radius: 2px;
}

.forms [type="checkbox"]:not(:checked) + label.checkbox-right:before,
.forms [type="checkbox"]:checked + label.checkbox-right:before {
  left: initial;
  right: 0;
}

.forms [type="checkbox"]:not(:checked) + label.checkbox-yellow:before,
.forms [type="checkbox"]:checked + label.checkbox-yellow:before {
  border: 1px solid hsla(47, 55%, 50%, 1);
}

.forms [type="checkbox"]:checked + label:before {
  background: $primary-blue;
}

.forms [type="checkbox"]:checked + label.checkbox-yellow:before {
  background: hsla(47, 55%, 50%, 1);
}

/* checked mark aspect */
.forms [type="checkbox"]:not(:checked) + label:after,
.forms [type="checkbox"]:checked + label:after {
  content: "✔";
  position: absolute;
  top: 3px;
  left: 1px;
  font-size: 1.7rem;
  line-height: 0.8;
  color: #fff;
  transition: all 0.2s;
}

.forms [type="checkbox"]:not(:checked) + label.checkbox-right:after,
.forms [type="checkbox"]:checked + label.checkbox-right:after {
  left: initial;
  right: 1px;
}

/* checked mark aspect changes */
.forms [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

.forms [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

/* disabled checkbox */
.forms [type="checkbox"]:disabled:not(:checked) + label:before,
.forms [type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: $grey-light;
  background-color: $grey-lighter;
}

.forms [type="checkbox"]:disabled:checked + label:after {
  color: $grey;
}
.forms [type="checkbox"]:disabled + label {
  color: $grey;
}

.disabled .forms {
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    box-shadow: none;
    border-color: $grey-light;
    background-color: $grey-lighter;
  }
  [type="checkbox"]:checked + label:after {
    color: $grey;
  }
  [type="checkbox"] + label {
    color: $grey;
  }
}

/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
  border: 1px dotted $primary-blue-darker;
}

[type="checkbox"]:checked:focus + label.checkbox-yellow:before,
[type="checkbox"]:not(:checked):focus + label.checkbox-yellow:before {
  border: 1px dotted hsla(47, 55%, 50%, 1);
}

.forms .checkbox label:hover:before {
  border: 1px solid $primary-blue-darker !important;
}

.forms .checkbox label.checkbox-yellow:hover:before {
  border: 1px solid hsla(47, 55%, 50%, 1) !important;
}

.forms .radios ul {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;

  text-align: left;
}

.forms .radios label {
  color: $grey-darker;
}
.forms .radios ul li {
  color: $grey;
  display: block;
  position: relative;
  width: 100%;
  margin-bottom: 8px;
}
.forms .radios.radios-inline ul li {
  display: inline-block;
  width: auto;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }

  label {
    position: relative;
    top: 3px;
    padding: 0 0 0 25px;
  }
}

.forms .radios ul li input[type="radio"] {
  position: absolute;
  visibility: hidden;
}

.forms .radios ul li label {
  display: block;
  display: inline-block;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  padding: 0px 0px 0px 25px;
  margin: 0;
  z-index: 9;
  cursor: pointer;
}

.forms .radios ul li:hover label {
  color: $font-primary;
  &.label--dark-blue {
    color: $secondary-blue;
  }
  &.label--black {
    color: $font-primary;
  }
}

.forms .radios ul li .check {
  display: inline-block;
  position: absolute;
  border: 2px solid $grey-light;
  border-radius: 100%;
  height: 18px;
  width: 18px;
  top: 5px;
  left: 0px;
  z-index: 5;
  transition: border 0.2s linear;
}

.forms .radios ul li:hover .check {
  border: 2px solid $font-primary;
  &.check--dark-blue {
    border: 2px solid $secondary-blue;
  }
  &.check--black {
    border: 2px solid $black;
  }
}

.forms .radios ul li .check::before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 8px;
  width: 8px;
  top: 3px;
  left: 3px;
  margin: auto;
  transition: background 0.2s linear;
}

.forms .radios input[type="radio"]:checked ~ .check {
  border: 2px solid $font-primary;
  &.check--black {
    border: 2px solid $black;
  }
}

.forms .radios input[type="radio"]:checked ~ .check::before {
  background: $font-primary;
}
.forms .radios input[type="radio"]:checked ~ .check--black::before {
  background: $black;
}

.forms .radios input[type="radio"]:checked ~ label {
  color: $primary-blue;
  &.label--dark-blue {
    color: $secondary-blue;
  }
  &.label--black {
    color: $font-primary;
    font-weight: bold;
  }
}

.forms .radio-as-button {
  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + label {
    font-size: 1.3rem;
    border: 1px solid $grey-lighter;
    color: $font-primary;
    cursor: pointer;
    text-align: center;
    padding: 0;
    line-height: 30px;
    padding: 0 5px;
    margin-bottom: 10px;
  }

  input[type="radio"]:checked + label {
    border-color: $primary-blue;
    font-weight: 700;
  }

  input[type="radio"] + label:hover {
    border-color: $primary-blue;
  }

  &.radio-as-button-inline {
    input[type="radio"] + label {
      display: inline-block;
      width: auto;
      margin-right: 10px;
    }
  }

  &.radio-as-button-flex {
    display: flex;
    flex-flow: row wrap;

    input[type="radio"] + label {
      flex: 1;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

.disabled .forms .radio-as-button,
.forms.disabled .radio-as-button,
.forms .radio-as-button.disabled {
  input[type="radio"]:checked + label {
    border-color: $grey;
  }
}

.forms .errorlist {
  text-align: left;
  font-size: 12px;
  padding-top: 3px;
}

.forms .errorlist li {
  padding: 0 !important;
  margin: 0;
  border: none;
  background-color: transparent;
  color: $error-red;
}
.forms .errorlist li:before {
  content: "";
}

.forms .errorlist li a {
  color: $primary-blue !important;
}

.forms input[type="range"] {
  background: transparent;
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  width: 100%;
  z-index: 1;

  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: transparent;
  }
  &::-webkit-slider-thumb {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: $primary-blue;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
  }
  &::-moz-range-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
  }
  &::-moz-range-thumb {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: $primary-blue;
    cursor: pointer;
  }
  &::-ms-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: #2a6495;
    border-radius: 2.6px;
  }
  &::-ms-fill-upper {
    background: $grey-lightest;
    border-radius: 2.6px;
  }
  &::-ms-thumb {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: $primary-blue;
    cursor: pointer;
  }
  &:focus::-ms-fill-lower {
    background: $grey-lightest;
  }
  &:focus::-ms-fill-upper {
    background: $grey-lightest;
  }
}

.forms .range-background {
  position: absolute;
  display: block;
  width: calc(100% - 10px);
  background-color: $grey-lighter;
  height: 3px;
  top: 13px;
}

.forms .range-line {
  position: relative;
  display: block;
  width: 0%;
  background-color: $primary-blue;
  height: 3px;
  top: -15px;
}

/* Copied from PrintPriceView.scss that should be cleaned after merge */
.forms.quantity {
  font-size: 0;

  form {
    display: inline-block;
    vertical-align: top;
  }

  label {
    display: inline-block;
    width: 70px;
    margin-bottom: 0;
    padding: 0;
    margin-left: 5px;
  }

  input[type="number"] {
    text-align: center;
    font-size: 1.8rem;
    padding: 0;
    -moz-appearance: textfield;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .minus,
  .plus {
    position: relative;
    display: inline-block;
    height: 29px;
    width: 27px;
    background: $grey-lightest;
    border-bottom: 1px solid $grey-light;
    user-select: none;
    vertical-align: top;
    text-align: center;
    font-size: 1.4rem;

    transition: all 0.2s ease;

    svg {
      vertical-align: middle;
      margin-top: 5px;
    }

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid $primary-blue;

      svg path {
        fill: $primary-blue;
      }
    }

    &:active {
      background-color: $primary-blue;
      svg path {
        fill: #ffffff;
      }
    }

    &[disabled]:hover {
      cursor: default;
      border-bottom: 1px solid $grey-light;
      svg path {
        fill: $grey;
      }
    }
  }

  .minus {
    border-right: 1px solid $grey-light;
  }

  .plus {
    border-right: 1px solid transparent;
  }

  //used in the cart when there is an items in batch ( more than 20 items)
  //we need to show a small message to expain why we can't change the quantity
  // + disabled the plus and minus
  // + show a hint
  .quantity-adjustment.disabled {
    pointer-events: none;
    cursor: default;
    display: none;
  }

  .text_quantity_batch {
    display: inline-block;
    width: 54px;
    font-size: 13px;
    text-align: left;
    font-style: italic;
  }

  .label_quantity_batch {
    .line {
      background: transparent;
    }
  }

  [class*="hint--"] {
    color: $grey;
    font-size: 14px;
    margin-left: 0;
    top: 2px;
    &:hover {
      color: $font-primary;
    }
  }
}

.forms .switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $grey;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    border-radius: 34px;

    &:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      transition: 0.4s;

      border-radius: 50%;
    }
  }

  input:checked + .slider {
    background-color: $primary-blue;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $primary-blue;
  }

  input:checked + .slider:before {
    transform: translateX(16px);
  }
}

.statement-form {
  margin-top: 10px;
  .hidden {
    display: none;
  }
}

#personalized-statement {
  color: red;
  font-size: 12px;
}
