.new-banner {
  position: absolute;
  color: white;
  font-weight: initial;
  background-color: #3e8ddd;
  padding: 8px 0;
  text-align: center;
  width: 100%;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);

  &.hidden {
    display: none;
  }

  .new-banner-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .new-banner-content {
      margin: 0 16px;

      .new-banner-text {
        font-weight: bold;
        font-size: 1.1em;

        cursor: pointer;

        a {
          color: white;
        }
      }
    }

    .new-banner-close {
      margin: 0 16px;
      font-size: 20px;
      cursor: pointer;
    }
  }
}

#sub-banner.new-banner {
  top: 70px;
  z-index: -1; //needs to be below the opened menu
}
