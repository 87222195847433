// Declares some variables used within the library.

// Prefix for all classes. By default, BEM naming convention is used
$hintPrefix: "hint--" !default;

// font size
$hintFontSize: 12px !default;

// default font family
$hintFontFamily: $primary-fontfamily;

// paddings
$hintVerticalPadding: 8px !default;
$hintHorizontalPadding: 10px !default;

// default tooltip height
$hintTooltipHeight: $hintFontSize + 2 * $hintVerticalPadding !default;

// border-width for tooltip arrow
$hintArrowBorderWidth: 6px !default;

// horizontal arrow offset
$hintArrowOffsetX: 2 * $hintArrowBorderWidth !default;

// transition distance
$hintTransitionDistance: 8px !default;

// Transition Duration
$hintTransitionDuration: 0.2s !default;

// Delay in showing the tooltips.
$hintShowDelay: 100ms !default;

// Delay in hiding the tooltips.
$hintHideDelay: 0ms !default;

// z-index for tooltips
$hintZIndex: 1000000 !default;

// Size options
$hintSizeSmall: 80px !default;
$hintSizeMedium: 150px !default;
$hintSizeLarge: 300px !default;

// Various colors
// Default color is blackish
$hintDefaultColor: $black !default;

//
// Place to store common mixins.

// Vendor prefixer mixin.
@mixin vendor($property, $value) {
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  #{$property}: $value;
}

// Generates border-color rules for all possible positions
@mixin arrow-border-color($color) {
  @each $position in top, bottom, left, right {
    @if $position == top or $position == bottom {
      // Loop further for classes like .top-left, bottom-right etc
      @each $xDir in left, right {
        .#{$hintPrefix}#{$position}-#{$xDir}:before {
          border-#{$position}-color: $color;
        }
      }
    }
    .#{$hintPrefix}#{$position}:before {
      border-#{$position}-color: $color;
    }
  }
}

// mixin to set margin on tooltip using translate transform
// $property
@mixin set-margin($property, $transitionDirection, $translateX: 0) {
  $value: unquote(
    "#{$property}(#{$hintTransitionDistance * $transitionDirection})"
  );
  &:before {
    @include vendor("transform", $value);
  }
  &:after {
    @if $translateX != 0 {
      // For vertical tooltips, we need to animate in y-direction
      // retaining its x-transform.
      @include vendor("transform", translateX($translateX) $value);
    } @else {
      @include vendor("transform", $value);
    }
  }
}

[class*="#{$hintPrefix}"] {
  position: relative;
  display: inline-block;

  &:before,
  &:after {
    position: absolute;

    // HACK: Trigger hardware accelerated rendering, otherwise transform was not
    // working on a hidden element
    @include vendor("transform", translate3d(0, 0, 0));

    // HACK: visibility is set to hidden because IE & Opera don't support
    // pointer-events on HTML content yet because of which hovering a hidden tooltip
    // shows the tooltip.
    visibility: hidden;
    opacity: 0;
    z-index: $hintZIndex;
    // shouldn't receive pointer events, otherwise even hovering tooltip will make it appear
    pointer-events: none;

    @include vendor("transition", $hintTransitionDuration ease);
    @include vendor("transition-delay", $hintHideDelay);
  }

  &:hover:before,
  &:hover:after {
    visibility: visible;
    opacity: 1;
  }

  &:hover:before,
  &:hover:after {
    // $hintShowDelay will apply as soon as element is hovered.
    @include vendor("transition-delay", $hintShowDelay);
  }

  /**
	 * tooltip arrow
	 */
  &:before {
    content: "";
    position: absolute;
    background: transparent;
    border: $hintArrowBorderWidth solid transparent;
    // move z-index 1 up than :after so that it shows over box-shadow
    z-index: $hintZIndex + 1;
  }

  /**
	 * tooltip body
	 */
  &:after {
    background: $hintDefaultColor;
    color: white;
    padding: $hintVerticalPadding $hintHorizontalPadding;
    font-size: $hintFontSize;
    font-family: $hintFontFamily;
    line-height: $hintFontSize; // Vertical centering.
    white-space: nowrap; // Prevent breaking to new line.
  }
  // Always get content from aria-label attribute.
  &[aria-label]:after {
    content: attr(aria-label); // The magic!
  }
  // If the `data-hint` attribute is present, use it.
  // This might be deprecated in future in support of a11y.
  &[data-hint]:after {
    content: attr(data-hint); // The magic!
  }
}

// Hide tooltips if any of the supported attributes is empty.
[aria-label=""],
[data-hint=""] {
  &:before,
  &:after {
    display: none !important;
  }
}

@mixin vertical-positioned-tooltip(
  $propertyY,
  $transitionDirection,
  $xDirection: 0
) {
  &:before {
    // bring arrow inside so that it animates to normal position when shown.
    // HACK: +1px to avoid the 1 px white space between arrow and body during transition.
    margin-#{$propertyY}: -2 * $hintArrowBorderWidth + 1px;
  }

  &:before,
  &:after {
    #{$propertyY}: 100%;
    left: 50%; // get top-left corner in center
  }

  &:before {
    left: calc(
      50% - #{$hintArrowBorderWidth}
    ); // get arrow center aligned with content
  }

  $translateX: -50%;
  @if $xDirection == -1 {
    $translateX: -100%;
  } @else if $xDirection == 1 {
    $translateX: 0;
  }

  &:after {
    @include vendor("transform", translateX($translateX));
  }

  &:after {
    @if $xDirection != 0 {
      // bring back the tooltip by some offset so that arrow doesn't stick at end
      margin-left: -$xDirection * $hintArrowOffsetX;
    }
  }

  &:hover {
    @include set-margin("translateY", $transitionDirection, $translateX);
  }
}

@mixin horizontal-positioned-tooltip($propertyX, $transitionDirection) {
  &:before {
    // bring arrow inside so that it animates to normal position when shown
    // HACK: +1px to avoid the 1 px white space between arrow and body during transition.
    margin-#{$propertyX}: -2 * $hintArrowBorderWidth + 1px;
    // bring back to center vertically
    margin-bottom: -1 * $hintArrowBorderWidth;
  }

  &:after {
    // bring back to center
    margin-bottom: -1 * floor(calc($hintTooltipHeight / 2));
  }

  &:before,
  &:after {
    #{$propertyX}: 100%;
    bottom: 50%;
  }

  &:hover {
    @include set-margin("translateX", $transitionDirection);
  }
}

/**
 * set default color for tooltip arrows
 */
@include arrow-border-color($hintDefaultColor);

/**
 * top tooltip
 */
.#{$hintPrefix}top {
  @include vertical-positioned-tooltip("bottom", -1);
}

/**
 * bottom tooltip
 */
.#{$hintPrefix}bottom {
  @include vertical-positioned-tooltip("top", 1);
}

/**
 * right tooltip
 */
.#{$hintPrefix}right {
  @include horizontal-positioned-tooltip("left", 1);
}

/**
 * left tooltip
 */
.#{$hintPrefix}left {
  @include horizontal-positioned-tooltip("right", -1);
}

/**
 * top-left tooltip
 */
.#{$hintPrefix}top-left {
  @include vertical-positioned-tooltip("bottom", -1, -1);
}

/**
 * top-right tooltip
 */
.#{$hintPrefix}top-right {
  @include vertical-positioned-tooltip("bottom", -1, 1);
}

/**
 * bottom-left tooltip
 */
.#{$hintPrefix}bottom-left {
  @include vertical-positioned-tooltip("top", 1, -1);
}

/**
 * bottom-right tooltip
 */
.#{$hintPrefix}bottom-right {
  @include vertical-positioned-tooltip("top", 1, 1);
}

.#{$hintPrefix}small,
.#{$hintPrefix}medium,
.#{$hintPrefix}large {
  &:after {
    white-space: normal;
    line-height: 1.4em;
    word-wrap: break-word; // Ensure long words do not overflow.
  }
}

.#{$hintPrefix}small {
  &:after {
    width: $hintSizeSmall;
  }
}
.#{$hintPrefix}medium {
  &:after {
    width: $hintSizeMedium;
  }
}
.#{$hintPrefix}large {
  &:after {
    width: $hintSizeLarge;
  }
}

[class*="#{$hintPrefix}"] {
  /**
	 * tooltip body
	 */
  &:after {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  }
}
