#promo {
  display: none;
}

body.with_promo_banner #promo {
  display: block;

  .banner-container {
    background-color: #044b92;
    color: white;
    height: $promo-banner-height;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000 !important;

    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;

    .banner-text {
      font-weight: bold;
      font-size: 1.1em;

      @media all and (max-width: 768px) {
        font-size: 1em;
      }

      a {
        color: white;
      }
    }

    .banner-close {
      cursor: pointer;
      vertical-align: middle;
      padding: 8px;
      font-weight: 400; // this wouldn't be needed if we used _reset on cms pages (WD-4015)

      .banner-close-text {
        text-decoration: underline;
        padding: 4px;

        @media all and (max-width: 768px) {
          // keep only the icon when to small
          display: none;
        }
      }
    }
  }
}

// fix menu when promo banner is active
body.with_promo_banner #header.header-desktop #header-wrapper {
  top: $promo-banner-height;
}

// fix menu when promo banner is active
body.with_promo_banner #header.header-mobile #header-wrapper {
  top: $promo-banner-height;

  @media only screen and (max-width: 768px) {
    top: $promo-banner-height;
  }
}

body.with_promo_banner #header-mobile-container {
  top: $promo-banner-height;

  @media only screen and (max-width: 768px) {
    top: $promo-banner-height;
  }
}

// fix wrapper/main div
body.with_promo_banner .s-base-wrapper {
  top: $promo-banner-height;
}

// below are old unreviewed rules, those are probably not needed anymore
body.with_promo_banner #designershop-content {
  margin-top: 16px;
}

body.with_promo_banner .header_shop_spacer {
  position: relative;
  top: 64px;
}
