@import "../_config/config";

.search-box {
  position: relative;

  &.search-box--margin-top-mobile {
    @media #{$small-and-down} {
      margin-top: 10px;
    }
  }

  form {
    position: relative;
  }

  input {
    border-radius: 0;
    transition: all 0.2s ease;
    height: 30px;

    padding: 0 0 0 5px;
    border: 1px solid $grey;
    font-size: 1.3rem;
    color: $grey-darker;

    &:active,
    &:focus,
    &:hover {
      background: $white;
      border-color: $secondary-blue;
    }
  }

  [type="submit"] {
    background: $secondary-blue;
    border: none;
    position: absolute;
    right: 0;
    height: 30px;
    width: 30px;
    bottom: 0;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    border: none;
    padding-left: 5px;

    &:hover {
      color: rgba(255, 255, 255, 1);
    }

    span:before {
      font-size: 2rem;
    }
  }

  .search-box-reset {
    display: none;

    position: absolute;
    transform: rotate(45deg);
    top: 9px;
    right: 40px;
    color: $grey;
    cursor: pointer;
    &:hover {
      color: $font-primary;
    }
  }
}

.disabled .search-box,
.search-box.disabled {
  input {
    background: $grey-lighter;
    border-color: $grey-light;
    &:active,
    &:focus,
    &:hover {
      background: $grey-lighter;
      border-color: $grey-light;
    }
  }

  [type="submit"] {
    background: $grey;
  }
}
