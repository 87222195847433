.flexbox {
  display: flex;
}

.flexbox--vertical-flex-start {
  align-items: flex-start;
}

.flexbox--vertical-center {
  align-items: center;
}

.flexbox--vertical-flex-end {
  align-items: flex-end;
}

.flexbox--vertical-baseline {
  align-items: baseline;
}

.flexbox--vertical-stretch {
  align-items: stretch;
}

.flexbox--full-height {
  height: 100%;
}

.flexbox--justify-left {
  justify-content: flex-start;
}

.flexbox--justify-center {
  justify-content: center;
}

.flexbox--justify-right {
  justify-content: flex-end;
}

.flexbox--space-between {
  align-content: space-between;
}

.flexbox--flex-direction-row {
  flex-direction: row;
}

.flexbox--flex-direction-column {
  flex-direction: column;
}

.flexbox--wrap {
  flex-wrap: wrap;
}
.flexbox--nowrap {
  flex-wrap: nowrap;
}
.flexbox--wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flexbox-item--flex {
  flex: 1;
}

.flexbox-item--no-grow {
  flex-grow: 0;
}

.flexbox-item--grow-1 {
  flex-grow: 1;
}
.flexbox-item--grow-2 {
  flex-grow: 2;
}
.flexbox-item--grow-3 {
  flex-grow: 3;
}
.flexbox-item--grow-4 {
  flex-grow: 4;
}

.flexbox-item--no-shrink {
  flex-shrink: 0;
}
