.separator {
  height: 2px;
  width: 250px;
  margin: auto;
  margin-bottom: 40px;
  margin-top: 10px;
  background: $primary-blue;
}

.separator--wide {
  width: 100%;
}

.separator--grey {
  background: $grey-lighter;
}

.separator--light-margin {
  margin: 10px auto;
}

.separator--bigger-margin-bottom {
  margin-bottom: 60px;
}

.separator--modal {
  width: 60%;

  @media (max-width: 600px) {
    .modal_login-signup .separator {
      width: 80%;
    }
  }
  @media (max-width: 400px) {
    .modal_login-signup .separator {
      width: 100%;
    }
  }
}

.separator--site {
  width: 40%;
  margin-bottom: 30px;
  @media (max-width: 600px) {
    margin-bottom: 20px;
    width: 80%;
  }
}

.separator--left-small {
  width: 8%;
  margin-bottom: 30px;
  margin-left: 0;
}

.or_separator {
  overflow: hidden;
  width: 60%;
  overflow: hidden;
  margin: 15px auto 15px auto;

  @media (max-width: 600px) {
    .modal_login-signup .or_separator {
      width: 80%;
    }
  }
  @media (max-width: 600px) {
    .modal_login-signup .or_separator {
      width: 100%;
    }
  }

  .text {
    display: block;
    font-size: 1.6rem;
    color: $primary-blue;
    float: left;
    width: 14%;
    text-align: center;
  }
  .line {
    display: block;
    position: relative;
    top: 10px;
    float: left;
    width: 43%;
    height: 1px;
    background: $primary-blue;
  }
}
