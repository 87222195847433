@import "../_config/config";

.box-selector {
  border: 1px solid $grey-light;
  cursor: pointer;
  user-select: none;
  display: block;
}

.box-selector:hover {
  border-color: $primary-blue;
}

.box-selector.selected {
  position: relative;
  border-color: $primary-blue;
  box-shadow: inset 0 0 0px 2px $primary-blue;

  &:after {
    content: "";
    width: 0;
    height: 0;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $primary-blue;
  }
}

.box-selector.disabled,
.box-selector.errors {
  border: 1px solid $grey-lightest;
  box-shadow: none;
  cursor: default;
  pointer-events: none;
}
