.container {
  margin: 0 auto;
  max-width: $max-container-width;
  width: 100%;
  // width: calc(100% - 20px);
  padding-left: 20px;
  padding-right: 20px;
  @media #{$small-and-down} {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.container--wide {
  max-width: 100%;
}

.container--medium {
  max-width: $medium-container-width;
  margin: 0 auto;
}

.container--wide-mobile {
  @media #{$small-and-down} {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}

.container .row {
  margin-left: calc(-1 * $gutter-width / 2);
  margin-right: calc(-1 * $gutter-width / 2);
}

.row {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  &.row-no-margin {
    margin-bottom: 0;
  }

  &.row-margin-bottom-gutter {
    margin-bottom: $gutter-width;
  }

  &.row-margin-bottom-gutter-big {
    margin-bottom: $gutter-width * 2;
  }

  &.row-margin-bottom-gutter-small {
    margin-bottom: $padding-size-small;
  }

  // Clear floating children
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  &.row-no-margin {
    margin-bottom: 0;
  }

  &.row-margin-bottom-gutter {
    margin-bottom: $gutter-width;
  }

  .col {
    float: left;
    box-sizing: border-box;
    padding: 0 calc($gutter-width / 2);
    min-height: 1px;

    &.col-padding-small {
      padding: 0 calc($gutter-width / 4);
    }

    &[class*="push-"],
    &[class*="pull-"] {
      position: relative;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote(calc(100 / ($num-cols / $i)) + "%");
      &._s#{$i} {
        width: $perc;
        margin-left: auto;
        left: auto;
        right: auto;
      }
      $i: $i + 1;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote(calc(100 / ($num-cols / $i)) + "%");
      &._offset-s#{$i} {
        margin-left: $perc;
      }
      &._pull-s#{$i} {
        right: $perc;
      }
      &._push-s#{$i} {
        left: $perc;
      }
      $i: $i + 1;
    }

    @media #{$medium-and-up} {
      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote(calc(100 / ($num-cols / $i)) + "%");
        &._m#{$i} {
          width: $perc;
          margin-left: auto;
          left: auto;
          right: auto;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote(calc(100 / ($num-cols / $i)) + "%");
        &._offset-m#{$i} {
          margin-left: $perc;
        }
        &._pull-m#{$i} {
          right: $perc;
        }
        &._push-m#{$i} {
          left: $perc;
        }
        $i: $i + 1;
      }
    }

    @media #{$large-and-up} {
      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote(calc(100 / ($num-cols / $i)) + "%");
        &._l#{$i} {
          width: $perc;
          margin-left: auto;
          left: auto;
          right: auto;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote(calc(100 / ($num-cols / $i)) + "%");
        &._offset-l#{$i} {
          margin-left: $perc;
        }
        &._pull-l#{$i} {
          right: $perc;
        }
        &._push-l#{$i} {
          left: $perc;
        }
        $i: $i + 1;
      }
    }

    @media #{$extra-large} {
      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote(calc(100 / ($num-cols / $i)) + "%");
        &._xl#{$i} {
          width: $perc;
          margin-left: auto;
          left: auto;
          right: auto;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote(calc(100 / ($num-cols / $i)) + "%");
        &._offset-xl#{$i} {
          margin-left: $perc;
        }
        &._pull-xl#{$i} {
          right: $perc;
        }
        &._push-xl#{$i} {
          left: $perc;
        }
        $i: $i + 1;
      }
    }
  }

  //allow 20% col, perfect for 5 cols
  .col._s20per {
    width: 20%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .col._offset_s20per {
    margin-left: 20%;
  }

  .col._push_s20per {
    left: 20%;
  }

  .col._pull_s20per {
    right: 20%;
  }

  @media #{$medium-and-up} {
    .col._m20per {
      width: 20%;
      margin-left: auto;
      left: auto;
      right: auto;
    }

    .col._offset_m20per {
      margin-left: 20%;
    }

    .col._push_m20per {
      left: 20%;
    }

    .col._pull_m20per {
      right: 20%;
    }
  }

  @media #{$large-and-up} {
    .col._l20per {
      width: 20%;
      margin-left: auto;
      left: auto;
      right: auto;
    }

    .col._offset_l20per {
      margin-left: 20%;
    }

    .col._push_l20per {
      left: 20%;
    }

    .col._pull_l20per {
      right: 20%;
    }
  }

  @media #{$extra-large} {
    .col._xl20per {
      width: 20%;
      margin-left: auto;
      left: auto;
      right: auto;
    }

    .col._offset_xl20per {
      margin-left: 20%;
    }

    .col._push_xl20per {
      left: 20%;
    }

    .col._pull_xl20per {
      right: 20%;
    }
  }
}
