//
// This is a custom grid-based layout.
// Rows can contain rows.
// Each row has a row-head meant for the title of the sub section and a row-body for the content (which can be another row)
// Columns will just layout its content as column
// The row-Nx class should match the sum of columns under the hierarchy of a row
// When on small screens, rows will layout their content as columns.
//

.materials-menu {
  // Layout
  .row div,
  .row a {
    /* debug */
    /* border: solid 1px rgb(153, 0, 255); */
  }
  .row {
    flex-grow: 1;
    margin: 0; // reset from the other .row  TODO: rename to menu-row?

    .row-head {
    }
    .row-body {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
  }
  .row-2x {
    flex-grow: 2;
  }
  .row-5x {
    flex-grow: 5;
  }

  .column {
    display: flex;
    flex-flow: column nowrap;
    min-width: 160px;
  }

  // small screen
  @media (max-width: $large-screen) {
    .row .row-body {
      flex-flow: column nowrap;
    }

    .row-material-type {
      margin-bottom: 24px;
    }

    .item,
    .cat-item {
      margin-bottom: 8px !important;
    }

    .column {
      height: auto;
    }
  }

  // large screen
  @media (min-width: $large-screen) {
  }

  // Content
  .item {
    color: $font-secondary-darkbackground;

    font-family: $primary-fontfamily;
    font-size: 1.4rem;
    font-weight: 400;

    padding-left: 6px;
    margin-bottom: 4px;

    &:hover {
      color: white;
    }
  }

  .spacer-item {
    height: 12px;
  }

  .cat-item {
    margin-top: 6px;
    font-family: "brandon-grotesque", sans-serif;
    color: #fff;
    font-size: 1.8rem;
  }

  .arrow-item {
    font-family: $secondary-fontfamily;
    color: $accent;
    margin-bottom: 20px;
    position: relative;
    font-size: 2rem;

    &:hover {
      color: $accent-light;
      text-decoration: underline;
    }

    &.nav-submenu-title-space {
      margin-top: 40px;
    }

    .nav-submenu-title-icon {
      font-size: 18px;
      margin-left: 8px;
      position: absolute;
      top: 50%;
      transform: translateY(-39%);
      transition: transform 0.3s ease;
    }

    &:hover .nav-submenu-title-icon {
      transform: translateY(-39%) translateX(3px);
    }
  }

  // Customisation of layout
  .row.row-root {
    max-width: 1380px;
    margin: 0 auto;
    .row-head {
      margin-bottom: 8px;
    }
  }

  .row.row-material-type {
    .row-head {
      font-family: $secondary-fontfamily;
      color: $accent;
      background-color: #5d656f;
      border-radius: 5px;
      padding: 0 8px 0 8px;
      margin-right: 16px;
      font-size: 1.8rem;
    }
    .row-body {
      padding: 0 16px 0 8px;
    }
  }

  // blue dot (for BASF materials)
  a.basf-material::after,
  span.basf-material-dot {
    content: "";
    height: 8px;
    width: 8px;
    background-color: $basf-blue;
    border-radius: 50%;
    border: 2px solid $basf-blue;
    display: inline-block;
    margin: 5px 0 0 8px;
    vertical-align: top;
  }

  span.basf-material-dot {
    margin: 6px 6px 0 0;
  }

  .basf-material-logo {
    width: 150px;
  }

  .basf-materials-legend {
    div {
      color: white;
      font-size: 1.4rem;
      font-weight: normal;
    }
  }

  .dim {
    opacity: 0.5;
  }

  .highlight {
    color: white;
    text-shadow: 1px 0px 0px white;
  }
}
