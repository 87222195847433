// generic
.footer-button {
  position: relative;
  display: inline-block;
  margin-top: 40px;

  padding: 3px 36px;
  background-color: $accent;
  border-radius: 3px;
  color: $font-primary;
  box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.2);
  text-decoration: none;

  &:hover {
    color: $font-primary;
    background-color: $accent-light;
    text-decoration: none;
  }

  @media only screen and (max-width: $medium-screen) {
    &.footer-button-space-bottom {
      margin-bottom: 40px;
    }
  }
}

#footer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  color: $white;
  font-family: $secondary-fontfamily;
  font-size: 2.2rem;
  font-weight: bold;

  text-align: left;

  // section

  .footer-section {
    display: flex;
    flex: 1;
    background-color: $font-primary;

    padding: 30px 40px;

    @media only screen and (max-width: $medium-screen) {
      padding: 40px 20px;
    }

    &[data-footer-information],
    &[data-footer-secure],
    &[data-footer-policy],
    &[data-footer-copyright] {
      background-color: #28292b;
    }
  }

  .footer-wrapper {
    position: relative;
    display: flex;
    flex: 1;

    max-width: 1380px;

    justify-content: space-between;
    margin: 0 auto;

    &.footer-wrapper--center {
      justify-content: center;

      @media only screen and (max-width: $medium-screen) {
        justify-content: flex-start;
      }
    }

    @media only screen and (max-width: $medium-screen) {
      flex-wrap: wrap;
    }
  }

  .footer-bloc {
    display: flex;

    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;

    flex-direction: column;

    margin: 8px;

    @media only screen and (max-width: $medium-screen) {
      flex-basis: 50%;
    }

    @media only screen and (max-width: $small-screen) {
      flex-basis: 100%;
    }
  }

  .footer-flexdefault {
    position: relative;
    align-items: center;
    display: flex;
    text-align: left;
    white-space: nowrap;
    justify-content: flex-start;
  }

  .footer-bloc-link {
    display: block;
    color: $font-secondary-darkbackground;
    margin: 4px 0;

    font-family: $primary-fontfamily;
    font-size: 1.4rem;
    font-weight: 400;

    &:hover {
      color: white;
    }

    &.footer-bloc-link--nohover:hover {
      color: $font-secondary-darkbackground;
    }
  }

  .footer-bloc-title {
    display: block;
    font-family: $secondary-fontfamily;
    color: $accent;
    margin-bottom: 20px;

    font-size: 2rem;

    &:hover {
      color: $accent-light;
    }

    &.footer-bloc-title--space {
      margin-top: 40px;
    }

    &.footer-bloc-title--white {
      color: $white;

      &:hover {
        color: $grey-light;
      }
    }

    &.footer-bloc-title--nohover:hover {
      color: $white;
    }
  }

  .footer-group {
    display: flex;
    margin-bottom: 30px;

    flex-direction: column;
  }

  .footer-ekomi {
    display: flex;
  }

  .footer-ekomi-aggregate-rating {
    font-family: $primary-fontfamily;
    font-size: 1.3rem;
    font-weight: normal;
    padding-top: 10px;
  }

  .logo-txt {
    padding-top: 0 !important;
  }

  .footer-social {
    display: flex;
  }

  .footer-social-content {
    display: flex;
    margin-bottom: 10px;
  }

  .footer-social-icon {
    font-size: 3rem;
    color: rgba(255, 255, 255, 0.7);
    flex: 1;

    span {
      padding: 10px;
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 9999px;
    }

    .icon-linkedin:before {
      color: rgba(255, 255, 255, 0.7);
    }

    &:hover {
      color: $white;
      text-decoration: none;

      span {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }

  .footer-newsletter {
    .footer-button {
      margin-top: 0px;
    }
  }

  .footer-languages {
    select {
      position: relative;
      display: block;
      height: 30px;
      width: 100%;
      border: 1px solid $grey-lighter;
      border-bottom: 1px solid $grey-light;
      background-color: $grey-lightest;
      box-shadow: none;
      font-family: $primary-fontfamily;
      font-size: 1.4rem;
      color: $font-primary;
      font-weight: normal;
      padding: 0 0 0 3px;

      -webkit-appearance: menulist;
    }

    .forms select:hover {
      border-color: $grey-light;
    }

    .forms select:focus {
      border-color: $primary-blue;
    }
  }

  .footer-separator {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #28292b;

    &:after {
      content: "";
      position: absolute;
      max-width: 1380px;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      background-color: $grey-dark;
    }
  }

  // secure
  // ---------------------------------
  // ---------------------------------
  .footer-secure {
    display: flex;
  }

  .footer-secure-payment-container {
    display: flex;

    @media only screen and (max-width: $small-screen) {
      flex-wrap: wrap;
    }
  }

  .footer-secure-payment {
    display: flex;
    flex: 1;

    .footer-secure-payment-background {
      background-color: $white;
      padding: 10px;
      border-radius: 3px;
      box-sizing: content-box;
      height: 30px;
      margin-right: 20px;
    }

    img {
      height: 20px;
      max-width: initial;
    }

    @media only screen and (max-width: $medium-screen) {
      flex-basis: 50%;
      margin-bottom: 10px;
    }

    @media only screen and (max-width: 400px) {
      flex-basis: 100%;
    }
  }

  .footer-seal-container {
    $width: 100px;

    max-width: $width;

    img {
      max-width: 100%;
      height: auto;
    }

    &.footer-seal-container-2x {
      max-width: 2 * $width;
    }

    &[data-footnote] {
      position: relative;
      &::before,
      &::after {
        font-size: 1.1rem;
        color: $font-secondary-darkbackground;
        font-family: $primary-fontfamily;
      }
      &::before {
        content: "*";
        position: absolute;
        top: 0;
        right: -15px;
      }
      &::after {
        content: "* " attr(data-footnote);
        position: absolute;
        top: calc(100% + 5px);
        left: -9px;
      }
      @media only screen and (max-width: $medium-screen) {
        &::after {
          position: unset;
          top: unset;
          left: unset;
          display: block;
        }
      }
    }
  }

  // address
  // ---------------------------------
  // ---------------------------------
  .footer-address-phone {
    color: $accent;

    &:hover {
      color: $accent-light;
      text-decoration: underline;
    }
  }

  .footer-address-phone--space {
    margin-top: 20px;
  }

  .footer-address-tollfree {
    font-size: 1.1rem;
  }

  [data-footer-policy].footer-section {
    padding-top: 0;
  }

  .footer-policy {
    .footer-bloc-link {
      margin-right: 20px;
      margin-left: 20px;
    }
  }

  [data-footer-copyright].footer-section {
    padding-top: 0;

    font-size: 1.8rem;
  }

  .footer-copyright {
    text-align: center;
  }
}

/*cookies section*/

#cookies-consent {
  position: fixed;
  z-index: 99999999;
  left: 2rem;
  bottom: 2rem;
  width: calc(100vw - 4rem);
  max-width: 450px;
  padding: 1rem;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 700px) {
  #cookies-consent {
    max-width: 100%;
    z-index: 99;
    bottom: unset;
    top: 9rem;
  }
}

#cookies-consent .cookies-inner {
  display: flex;
  align-items: center;

  p {
    font-weight: 400;
    margin: 0;
    text-align: left;
  }

  button {
    margin-left: 20px;
  }
}
