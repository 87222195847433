@import "../_config/config";

.notification-container {
  [class^="notification-"],
  [class*="notification-"] {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.notification-warn,
.notification-err,
.notification-info,
.notification-success {
  padding: 5px 5px 5px 25px;
  border-radius: 2px;
  border: none;
  color: $white;
  text-align: left;
  display: block;
  position: relative;
  &.hidden {
    display: none;
  }
}

._extend_before_li {
  font-family: "icomoon";
  position: absolute;
  left: 5px;
  font-size: 1.6rem;
  top: 50%;
  transform: translateY(-50%);
}

.notification-err {
  background: $notification-error-red;
  &:before {
    content: "\57";
    @extend ._extend_before_li;
  }
}

.notification-warn {
  background: $notification-warn-yellow;
  color: $font-primary;
  &:before {
    content: "\e887";
    @extend ._extend_before_li;
  }
}

.notification-info {
  background: $notification-info-blue;
  &:before {
    content: "\48";
    @extend ._extend_before_li;
  }
}

.notification-success {
  background-color: $green;
  &:before {
    content: "\2b";
    @extend ._extend_before_li;
  }
}

.notification-no-icon {
  padding: 5px;

  &:before {
    content: "";
    display: none;
  }
}
