@font-face {
  font-family: "icomoon";
  font-display: auto;
  src: url("fonts/icomoon.woff?k7rzyx") format("woff"),
    url("fonts/icomoon.ttf?k7rzyx") format("truetype");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cloud_upload:before {
  content: "\e963";
}
.icon-shopping_cart1:before {
  content: "\e964";
}
.icon-close:before {
  content: "\e961";
}
.icon-download:before {
  content: "\e95c";
}
.icon-logo-sculpteo:before {
  content: "\e95b";
}
.icon-scale-issue:before {
  content: "\e959";
}
.icon-unavailable:before {
  content: "\e95a";
}
.icon-left-arrow:before {
  content: "\e955";
}
.icon-minus:before {
  content: "\e956";
}
.icon-plus:before {
  content: "\e957";
}
.icon-right-arrow:before {
  content: "\e958";
}
.icon-linkedin:before {
  content: "\e952";
  color: #0077b5;
}
.icon-big-hole:before {
  content: "\e942";
  color: #49e;
}
.icon-closed-shapes:before {
  content: "\e945";
  color: #49e;
}
.icon-common-line .path1:before {
  content: "\e946";
  color: rgb(146, 146, 146);
}
.icon-common-line .path2:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(146, 146, 146);
}
.icon-common-line .path3:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(247, 6, 0);
}
.icon-intersection .path1:before {
  content: "\e949";
  color: rgb(146, 146, 146);
}
.icon-intersection .path2:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(146, 146, 146);
}
.icon-intersection .path3:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(247, 6, 0);
}
.icon-manifold:before {
  content: "\e94c";
  color: #49e;
}
.icon-non-manifold:before {
  content: "\e94d";
  color: #f70600;
}
.icon-open-lines-shapes:before {
  content: "\e94e";
  color: #f70600;
}
.icon-small-hole .path1:before {
  content: "\e94f";
  color: rgb(146, 146, 146);
}
.icon-small-hole .path2:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(247, 6, 0);
}
.icon-move-viewer_mode-configuration:before {
  content: "\e93e";
}
.icon-move-viewer_mode-preview:before {
  content: "\e93f";
}
.icon-reset-view:before {
  content: "\e940";
}
.icon-select-viewer:before {
  content: "\e941";
}
.icon-zoom-minus:before {
  content: "\e943";
}
.icon-zoom-plus:before {
  content: "\e944";
}
.icon-Sculpteo_Home-hp-jet-fusion:before {
  content: "\e93d";
  color: #2699ee;
}
.icon-move-viewer:before {
  content: "\e938";
}
.icon-rotate-viewer:before {
  content: "\e939";
}
.icon-Sculpteo_Home-exone-s:before {
  content: "\e93a";
  color: #2699ee;
}
.icon-timer-economy:before {
  content: "\e93b";
}
.icon-zoom-viewer:before {
  content: "\e93c";
}
.icon-solidity:before {
  content: "\e934";
}
.icon-boussole:before {
  content: "\e928";
}
.icon-coupe:before {
  content: "\e929";
}
.icon-cube:before {
  content: "\e92a";
}
.icon-dossier:before {
  content: "\e92b";
}
.icon-epais:before {
  content: "\e92c";
}
.icon-evidement:before {
  content: "\e92d";
}
.icon-fragil:before {
  content: "\e92e";
}
.icon-grille:before {
  content: "\e92f";
}
.icon-note:before {
  content: "\e930";
}
.icon-palette:before {
  content: "\e931";
}
.icon-parametre:before {
  content: "\e932";
}
.icon-serie:before {
  content: "\e933";
}
.icon-info2:before {
  content: "\e925";
}
.icon-link:before {
  content: "\e926";
}
.icon-timer:before {
  content: "\e927";
}
.icon-down-arrow:before {
  content: "\e921";
}
.icon-user:before {
  content: "\e919";
}
.icon-Sculpteo_Home-02:before {
  content: "\e901";
  color: #4193d0;
}
.icon-Sculpteo_Home-03:before {
  content: "\e900";
  color: #2699ee;
}
.icon-amt:before {
  content: "\e913";
  color: #5493cf;
}
.icon-ai:before {
  content: "\e911";
  color: #4193d0;
}
.icon-report:before {
  content: "\e912";
  color: #4193d0;
}
.icon-Sculpteo_Home-01:before {
  content: "\e902";
  color: #2699ee;
}
.icon-Sculpteo_Home-ZCORP:before {
  content: "\e903";
  color: #2699ee;
}
.icon-Sculpteo_Home-SLS:before {
  content: "\e904";
  color: #2699ee;
}
.icon-Sculpteo_Home-projet:before {
  content: "\e905";
  color: #2699ee;
}
.icon-Sculpteo_Home-OBJET:before {
  content: "\e906";
  color: #2699ee;
}
.icon-Sculpteo_Home-NEWSLETTER:before {
  content: "\e907";
  color: #2699ee;
}
.icon-Sculpteo_Home-EBOOK:before {
  content: "\e908";
  color: #2699ee;
}
.icon-Sculpteo_Home-DMLS:before {
  content: "\e909";
  color: #2699ee;
}
.icon-Sculpteo_Home-CLIP:before {
  content: "\e90a";
  color: #2699ee;
}
.icon-Sculpteo_Home-BLOG:before {
  content: "\e90b";
  color: #2699ee;
}
.icon-home:before {
  content: "\68";
}
.icon-pencil:before {
  content: "\65";
}
.icon-paypal:before {
  content: "\70";
}
.icon-warning:before {
  content: "\57";
}
.icon-spam:before {
  content: "\45";
}
.icon-info:before {
  content: "\48";
}
.icon-equalizer:before {
  content: "\73";
}
.icon-basket:before {
  content: "\21";
}
.icon-coin:before {
  content: "\24";
}
.icon-clock:before {
  content: "\23";
}
.icon-wrench:before {
  content: "\a3";
}
.icon-database:before {
  content: "\26";
}
.icon-file:before {
  content: "\27";
}
.icon-code:before {
  content: "\3c";
}
.icon-monitor:before {
  content: "\5b";
}
.icon-tree:before {
  content: "\6d";
}
.icon-truck:before {
  content: "\2b";
}
.icon-credit:before {
  content: "\63";
}
.icon-users:before {
  content: "\2d";
}
.icon-lock:before {
  content: "\6c";
}
.icon-cloud:before {
  content: "\6e";
}
.icon-crop_square:before {
  content: "\e962";
}
.icon-arrow_upward:before {
  content: "\e95d";
}
.icon-arrow_forward:before {
  content: "\e95e";
}
.icon-arrow_downward:before {
  content: "\e95f";
}
.icon-arrow_back:before {
  content: "\e960";
}
.icon-assignment:before {
  content: "\e85d";
}
.icon-mode_edit:before {
  content: "\e254";
}
.icon-payment:before {
  content: "\e8a1";
}
.icon-favorite:before {
  content: "\e87d";
}
.icon-lock2:before {
  content: "\e897";
}
.icon-shopping_cart:before {
  content: "\e8cc";
}
.icon-local_offer:before {
  content: "\e54e";
}
.icon-mail_outline:before {
  content: "\e0e1";
}
.icon-notifications:before {
  content: "\e7f4";
}
.icon-notifications_active:before {
  content: "\e7f7";
}
.icon-notifications_none:before {
  content: "\e7f5";
}
.icon-people_outline:before {
  content: "\e7fc";
}
.icon-perm_contact_calendar:before {
  content: "\e8a3";
}
.icon-store_mall_directory:before {
  content: "\e563";
}
.icon-swap_horiz:before {
  content: "\e8d4";
}
.icon-view_compact:before {
  content: "\e42b";
}
.icon-search:before {
  content: "\e951";
}
.icon-check:before {
  content: "\e5ca";
}
.icon-sync:before {
  content: "\e627";
}
.icon-photo_size_select_actual:before {
  content: "\e432";
}
.icon-fullscreen_exit:before {
  content: "\e922";
}
.icon-fullscreen:before {
  content: "\e923";
}
.icon-help_outline:before {
  content: "\e924";
}
.icon-burst_mode:before {
  content: "\e43c";
}
.icon-fullscreen2:before {
  content: "\e5d0";
}
.icon-star:before {
  content: "\e838";
}
.icon-share:before {
  content: "\e80d";
}
.icon-star_border:before {
  content: "\e83a";
}
.icon-help:before {
  content: "\e887";
}
.icon-home-backend:before {
  content: "\e88a";
}
.icon-question_answer:before {
  content: "\e91d";
}
.icon-compare:before {
  content: "\e91c";
}
.icon-upload:before {
  content: "\e90c";
}
.icon-account:before {
  content: "\e90d";
}
.icon-newsletter:before {
  content: "\e918";
}
.icon-address-book:before {
  content: "\e954";
}
.icon-bin:before {
  content: "\e935";
}
.icon-image:before {
  content: "\e936";
}
.icon-eye-blocked:before {
  content: "\e91f";
}
.icon-views:before {
  content: "\e91f";
}
.icon-vision:before {
  content: "\e91f";
}
.icon-visit:before {
  content: "\e91f";
}
.icon-banned:before {
  content: "\e91f";
}
.icon-blocked:before {
  content: "\e91f";
}
.icon-forbidden:before {
  content: "\e91f";
}
.icon-private:before {
  content: "\e91f";
}
.icon-eye:before {
  content: "\e920";
}
.icon-views2:before {
  content: "\e920";
}
.icon-vision2:before {
  content: "\e920";
}
.icon-visit2:before {
  content: "\e920";
}
.icon-bullhorn:before {
  content: "\e91e";
}
.icon-quotes-right:before {
  content: "\e978";
}
.icon-make-group:before {
  content: "\ea58";
}
.icon-earth:before {
  content: "\e91a";
}
.icon-globe:before {
  content: "\e91a";
}
.icon-language:before {
  content: "\e91a";
}
.icon-web:before {
  content: "\e91a";
}
.icon-internet:before {
  content: "\e91a";
}
.icon-sphere:before {
  content: "\e91a";
}
.icon-planet:before {
  content: "\e91a";
}
.icon-history:before {
  content: "\e91b";
}
.icon-time:before {
  content: "\e91b";
}
.icon-archive:before {
  content: "\e91b";
}
.icon-past:before {
  content: "\e91b";
}
.icon-your-objects:before {
  content: "\e90e";
}
.icon-menu:before {
  content: "\e910";
}
.icon-logout:before {
  content: "\e90f";
}
.icon-google-plus:before {
  content: "\e914";
}
.icon-facebook:before {
  content: "\e915";
}
.icon-twitter:before {
  content: "\e916";
}
.icon-youtube:before {
  content: "\e917";
}
.icon-filter:before {
  content: "\e953";
}
.icon-floppy-o:before {
  content: "\e937";
}
.icon-save:before {
  content: "\e937";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-pinterest:before {
  content: "\ead1";
}
