//hack for display inline-block
.inline-block-font-0 {
  font-size: 0px;
}

.heading {
  font-size: 3.2rem;

  font-weight: bold;
  text-align: center;

  padding-top: 10px;
  padding-bottom: 20px;

  line-height: 4rem;

  font-family: $secondary-fontfamily;
}

.heading--padding-bottom {
  padding-bottom: 20px;
}

.heading--left {
  text-align: left;
}

.heading--xl {
  font-size: 3.6rem;
}

.heading--sm {
  font-size: 2.8rem;
}

.heading--blue {
  color: $primary-blue;
}

.heading--white {
  color: $white;
}

.heading--no-padding {
  padding: 0;
}

.sub-heading {
  font-size: 2.4rem;
  line-height: 3.6rem;

  font-weight: bold;
  text-align: center;

  padding-top: 5px;
  padding-bottom: 10px;

  font-family: $secondary-fontfamily;
}

.sub-heading--padding-bottom {
  padding-bottom: 20px;
}

.sub-heading--left {
  text-align: left;
}

.sub-heading--xl {
  font-size: 2.6rem;
}

.sub-heading--sm {
  font-size: 2.2rem;
}

.sub-heading--xs {
  font-size: 2rem;
}

.sub-heading--blue {
  color: $primary-blue;
}

.sub-heading--white {
  color: $white;
}

.sub-heading--bold {
  font-weight: bold;
}

.text {
  font-size: 1.4rem;

  font-weight: 400;

  margin-bottom: 1rem;

  line-height: 2.1rem;

  .p-no-margin {
    margin-bottom: 0;
  }
}

.text--xs {
  font-size: 1.2rem;
}
.text--sm {
  font-size: 1.3rem;
}
.text--m {
  font-size: 1.4rem;
}
.text--xl {
  font-size: 1.6rem;
}
.text--xxl {
  font-size: 1.8rem;
}

.text--bold {
  font-weight: 700;
}
.text--italic {
  font-style: italic;
}
.text--blue {
  color: $primary-blue;
}

.text--primary-color {
  color: $primary;
}

.text--darkblue {
  color: $dark-blue;
}
.text--black {
  color: $font-primary;
}
.text--white {
  color: $white;
}
.text--red {
  color: $error-red;
}
.text--infos-red {
  color: $error-red;
}
.text--soft-red {
  color: $soft-red;
}
.text--grey {
  color: $grey;
}
.text--grey-light {
  color: $grey-light;
}
.text--grey-darker {
  color: $grey-darker;
}
.text--grey-dark {
  color: $grey-dark;
}

.text--align-left {
  text-align: left;
}
.text--align-right {
  text-align: right;
}
.text--align-center {
  text-align: center;
}

.text--spacing-01 {
  letter-spacing: 0.1rem;
}

//used for small text in the bottom of modal/page.
//currently use in login/singup/reset password
.text--notice {
  font-size: 1.3rem;
  margin-top: 20px;
  margin-bottom: 0;
}

//used for text before a form to explain something
//currently use in login/singup/reset password
.text--information {
  padding-bottom: 20px;
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0;
}

.text--information-warning {
  padding-bottom: 20px;
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0;

  color: $error-red;
}

.space {
  display: block;
  height: 2rem;
}

a {
  color: $primary-blue;
  &:hover {
    color: $primary-blue-darker;
  }
}

a.text--darkblue {
  &:hover {
    color: $dark-blue-lighter;
  }
}

.reset--line-height {
  line-height: normal;
}

// truncate text rules

.text--overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text--list-style-before {
  padding-left: 10px;
  position: relative;

  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 50%;
    width: 6px;
    height: 2px;
    border-radius: 50px;

    background-color: $grey-light;
  }
}

// update of typography based on the changes down on CMS

.heading-secondary {
  font-family: $secondary-fontfamily;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 3.6rem;
  color: $font-primary;
}

.heading-secondary-medium {
  font-family: $secondary-fontfamily;
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 4.8rem;
  color: $font-primary;
}

.heading-secondary-large {
  font-family: $secondary-fontfamily;
  font-size: 4.2rem;
  line-height: 6rem;
  color: $font-primary;
}

.heading-secondary-extra-large {
  font-family: $secondary-fontfamily;
  font-size: 6.4rem;
  font-weight: bold;
  line-height: 8.2rem;
  color: $font-primary;
}

.heading-secondary--space-bottom {
  margin-bottom: 40px;
}

.heading-secondary--second-color {
  color: $secondary;
}

.paragraphe {
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: $font-primary;
}

.heading-tertiary {
  font-family: $secondary-fontfamily;
  font-size: 2rem;
  font-weight: bold;
  line-height: 3rem;
  color: $font-primary;
}

.heading-tertiary--space-bottom {
  margin-bottom: 20px;
}
