@import "../_config/config";

body.background-color {
  background-color: $grey-background;
}

.card {
  background: #fff;
  padding: $gutter-width;
  margin-top: $gutter-width;

  border-radius: 2px;

  box-shadow: $shadow-card;

  &.disabled {
    position: relative;
  }

  &.disabled:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    user-select: none;
    z-index: 99;
  }

  &.card--blue {
    background: $secondary-blue;
    color: $white;
  }
  &.card--border {
    border-top: 2px solid $primary-blue;
  }

  &.card-no-margin {
    margin-top: 0;
  }

  &.card-no-padding-bottom {
    padding-bottom: 0;
  }

  &.card-padding-top-small {
    padding-top: $padding-size-small;
  }

  &.effect-hover {
    transition: box-shadow 0.2s ease;
    &:hover {
      box-shadow: $shadow-card-hover;
    }
  }
}
