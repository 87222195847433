.header-hero {
  position: relative;
  min-height: 500px;
  margin-bottom: 20px;
  color: $white;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  font-family: $secondary-fontfamily;

  @media #{$medium-and-down} {
    min-height: 400px;
  }
  @media #{$small-and-down} {
    min-height: 350px;
  }

  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.header-hero--sm {
  min-height: 300px;
}

.header-hero--no-overflow {
  overflow: hidden;
}

.header-hero-content {
  position: absolute;
  max-width: 75%;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;

  @media (max-width: $large-screen) {
    max-width: 90%;
  }

  @media #{$medium-and-down} {
    max-width: 100%;
    padding: 0 10px;
  }
}

.header-hero-content.header-hero-content-author {
  top: 20px;
  transform: translateY(0) translateX(-50%);
}

.header-hero-author-picture {
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  margin: 0 auto;
  border: 5px solid #fff;
  border-radius: 50%;
  margin-bottom: 10px;
  background-color: $grey-lightest;

  @media #{$small-and-down} {
    width: 100px;
    height: 100px;
  }

  img {
    background-repeat: no-repeat;
    background-position: 50%;
    width: inherit;
    height: inherit;
    border-radius: 50%;

    //important otherwise the img is not center
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

.header-hero-heading {
  font-size: 6rem;
  text-transform: uppercase;
  padding-bottom: 20px;

  font-weight: 700;
  line-height: 65px;

  @media #{$medium-and-down} {
    line-height: 40px;
    font-size: 4rem;
  }

  @media #{$small-and-down} {
    font-size: 3rem;
  }

  &.header-hero-heading--sm {
    @media #{$medium-and-up} {
      font-size: 2.6rem;
      line-height: 30px;
    }

    @media #{$large-and-up} {
      font-size: 3rem;
      line-height: 35px;
    }

    @media #{$extra-large} {
      font-size: 4rem;
      line-height: 45px;
    }

    @media only screen and (min-width: 1450px) {
      font-size: 5rem;
      line-height: 55px;
    }

    @media #{$medium-and-down} {
      font-size: 2.4rem;
      line-height: 27px;
    }

    @media #{$small-and-down} {
      font-size: 2rem;
      line-height: 24px;
    }
  }
}

.header-hero-sub-heading,
// little hack to insure that body:not(.page-cms) p is override
body p.header-hero-sub-heading {
  font-size: 2.6rem;
  line-height: 3.2rem;
  //text-transform: uppercase;

  @media #{$medium-and-down} {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  &.header-hero-sub-heading--sm {
    font-size: 2rem;

    @media #{$extra-large} {
      font-size: 2.2rem;
    }

    @media only screen and (min-width: 1450px) {
      font-size: 2.6rem;
    }

    @media #{$medium-and-down} {
      font-size: 1.6rem;
    }

    @media #{$small-and-down} {
      font-size: 1.4rem;
    }
  }
}

.header-hero-cta {
  margin-top: 40px;
}

//promotional product
.header-hero--promo {
  color: $font-primary;
  box-shadow: 0 4px 5px 0px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05);

  background-image: url(../images/home/home_promo/background_gifts.jpg);
  // only for raspberry
  @media only screen and (max-width: 1450px) {
    background-image: url(../images/home/home_promo/background_gifts_1200.jpg);
  }

  @media #{$medium-and-down} {
    background-image: url(../images/home/home_promo/background_gifts_1024.jpg);
  }

  @media #{$medium-and-down} {
    min-height: 450px;
  }

  &:after {
    display: none;
  }

  .header-hero-image-top {
    position: relative;
    margin-bottom: 30px;
    margin-top: 20px;

    img {
      max-width: 100%;
      height: auto;
    }

    @media #{$medium-and-down} {
      margin-bottom: 15px;
    }
  }

  .header-hero-heading {
    @media #{$medium-and-up} {
      font-size: 4rem;
      line-height: 4.6rem;
      padding-bottom: 15px;
    }
    @media #{$medium-and-down} {
      font-size: 3.6rem;
      line-height: 4.2rem;
      padding-bottom: 20px;
    }

    @media #{$small-and-down} {
      font-size: 3.4rem;
    }

    @media only screen and (max-width: 375px) {
      font-size: 2.8rem;
    }
  }

  .header-hero-content--promo {
    max-width: 100%;

    @media #{$extra-large} {
      max-width: 85%;
    }

    @media only screen and (min-width: 1450px) {
      max-width: 65%;
    }
  }

  .header-hero-image-product {
    @media #{$medium-and-down} {
      display: none;
    }
  }

  .header-hero-image-product-desktop {
    @media #{$medium-and-down} {
      display: none;
    }
  }

  .header-hero-image-product-mobile {
    display: none;
    max-width: 400px;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    img {
      width: 100%;
      max-width: 100px;
      height: auto;
    }
    @media #{$medium-and-down} {
      display: block;
    }
  }

  .header-hero-cta {
    margin-top: 60px;

    @media #{$medium-and-down} {
      margin-top: 30px;
    }

    @media #{$small-and-down} {
      margin-top: 30px;
    }
  }
}
