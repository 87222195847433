.nav-flexdefault {
  position: relative;
  align-items: center;
  display: flex;
  text-align: left;
  white-space: nowrap;
  justify-content: flex-start;
}

#header.header-mobile {
  height: 70px;
  line-height: initial;

  // everything is scope inside #header.header-mobile
  // -------------------------------------------------
  // -------------------------------------------------
  // -------------------------------------------------
  // -------------------------------------------------
  // -------------------------------------------------

  #header-wrapper {
    position: fixed;
    height: inherit;
    top: 0;
    width: 100%;
    z-index: 99;

    background-color: $white;
    color: $font-primary;
    font-family: $secondary-fontfamily;
    font-size: 2.2rem;
    font-weight: bold;
    box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  }

  #navbar {
    position: relative;
    height: 100%;

    padding: 0 20px;
  }

  #nav-main {
    height: 100%;
  }

  .nav-wrapper {
    margin: 0 auto;
    display: flex;
  }

  .nav-hamburger-bar {
    width: 30px;
    height: 4px;
    background-color: $font-primary;
    border-radius: 3px;

    &:not(:last-child) {
      margin-bottom: 7px;
    }
  }

  #nav-logo {
    margin-left: 20px;

    .sculpteo-logo {
      width: 148px;
      max-width: initial;
      display: flex;
    }

    @media only screen and (max-width: 600px) {
      width: 30px;
      overflow: hidden;
      .sculpteo-logo {
        clip-path: circle(14.68% at 17px 15px);
        margin-top: 10px;
      }
    }
  }

  #nav-logo-pro {
    font-size: 1.6rem;
    position: absolute;
    color: $black;
    font-family: $primary-fontfamily;
    font-weight: bold;
    margin-left: 204px;
    bottom: 27px;

    @media only screen and (max-width: 600px) {
      margin-left: 50px;
      bottom: 2px;
    }
  }

  .nav-bloc {
    flex-shrink: 1;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
    display: flex;
    width: 100%;
  }

  .nav-bloc-right {
    justify-content: flex-end;
  }

  .nav-link {
    @media only screen and (max-width: 600px) {
      font-size: 1.8rem;
    }
  }

  .nav-login {
    padding-right: 40px;
    @media only screen and (max-width: 600px) {
      padding-right: 20px;
    }
  }

  .nav-signup {
    padding: 3px 36px;
    background-color: $primary;
    border-radius: 3px;
    color: $white;
    box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.2);
    text-decoration: none;

    &:hover {
      color: $white;
      background-color: $primary-light;
      text-decoration: none;
    }

    @media only screen and (max-width: 600px) {
      padding: 3px 18px;
    }
  }

  .nav-shopping {
    position: relative;
    margin-right: 10px;
    font-size: 2.6rem;
    text-decoration: none;
    color: $font-primary;
    padding-left: 15px;
    padding-right: 15px;

    &:hover {
      color: $primary;
    }

    .nav-shopping-badge {
      position: absolute;
      top: -15px;
      right: -3px;
      background-color: $grey-light;
      color: $white;
      font-size: 1.4rem;
      text-align: center;
      padding: 3px;
      min-width: 25px;
      border-radius: 200px;
      font-family: $primary-fontfamily;

      transition: background-color 0.5s ease;

      &.badge-active {
        background-color: $soft-red;
      }
    }
  }

  // END of header
  // -------------------------------------------------
  // -------------------------------------------------
  // -------------------------------------------------
  // -------------------------------------------------
  // -------------------------------------------------
}

// START #header-mobile-canvas
// also scope inside #header-mobile-canvas
// avoid css bleeding
// -------------------------------------------------
// -------------------------------------------------
// -------------------------------------------------
// -------------------------------------------------
// -------------------------------------------------

#nav-mobile-overlay {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;

  background-color: rgba(0, 0, 0, 0.6);

  opacity: 0;
  visibility: hidden;
  transition: opacity 250ms ease, visibility 250ms ease;

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

#header-mobile-container {
  position: fixed;
  height: inherit;
  top: 0;
  z-index: 999;
  height: 100%;

  text-align: left;
  font-family: $secondary-fontfamily;
  font-size: 2.2rem;
  font-weight: bold;

  .nav-m-wrapper {
    display: flex;
    flex-direction: column;

    background-color: $font-primary;

    min-width: 265px;
    max-width: calc(100vw - 50px);
    width: 80vw;

    height: 100%;

    position: fixed;

    transition: transform 250ms ease-out;

    transform: translateX(-100%);

    will-change: transform;
  }

  .nav-m-wrapper-translateX {
    transform: translateX(0);
  }

  .nav-m-content {
    overflow-y: scroll;
    position: relative;
    overflow-x: hidden;
    height: 100%;
  }

  .nav-flexdefault {
    align-items: flex-start;
    flex: 1;
  }

  .nav-m-slide {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;

    transition: transform 250ms ease-out;

    will-change: transform;

    overflow-y: hidden;
  }

  .nav-m-slide-visible {
    overflow-y: auto;
  }

  .nav-m-slide-translateX-left {
    transform: translateX(-100%);
  }

  .nav-m-slide-translateX-right {
    transform: translateX(100%);
  }

  .nav-m-section {
    padding: 20px;
    flex: 1;
  }

  .nav-user {
    padding: 20px 20px 10px 20px;
    border-bottom: 4px solid $font-disabled;
    flex: 1;
    background-color: $white;
    color: $font-primary;
  }

  .nav-link-hat {
    font-size: 1.3rem;
    color: $font-secondary;
    position: absolute;
    top: 7px;
  }

  .nav-m-section-link {
    display: flex;
    position: relative;
    align-items: center;
    color: $white;
    font-size: 2rem;

    .nav-link-icon {
      padding-right: 20px;
      color: $font-disabled;
      font-size: 2.2rem;
      position: relative;

      opacity: 0.6;
    }

    .nav-link-icon-right {
      margin-left: auto;
    }

    &:hover {
      text-decoration: none;
      color: $white;
      .nav-link-icon {
        color: $white;
      }
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    // &[data-nav-m-link-value="nav-m-slide-account"]{
    // 	color: $accent;

    // 	&:hover{
    // 		color: $accent-light;
    // 		.nav-link-icon{
    // 			color: $accent;
    // 		}
    // 	}
    // }

    .nav-link-hat {
      color: rgba(255, 255, 255, 0.5);
      top: -12px;
    }
  }

  .nav-user-event-badge {
    position: absolute;
    top: -10px;
    left: 13px;
    background-color: $accent;
    color: $white;
    font-size: 1.2rem;
    text-align: center;
    padding: 3px;
    min-width: 25px;
    border-radius: 200px;
    font-family: $primary-fontfamily;

    display: none;

    &.nav-user-event-badge-visible {
      display: block;
    }

    &.nav-user-event-badge-critical {
      background-color: $soft-red;
    }
  }

  .nav-user-logout {
    position: relative;
    padding-top: 20px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% + 40px);
      height: 1px;
      top: 0;
      left: -20px;
      background-color: $grey-lightest;
      opacity: 0.3;
    }
  }

  .nav-m-section-sublink {
    display: flex;
    color: $font-secondary-darkbackground;
    margin: 12px 0;

    font-family: $primary-fontfamily;
    font-size: 1.5rem;
    font-weight: 400;

    &:hover {
      color: white;
    }
  }

  .nav-m-section-heading {
    display: flex;
    color: $white;
    padding: 20px;
    background-color: lighten($font-primary, 6%);
    align-items: center;

    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    .nav-link-icon {
      font-size: 3rem;
      margin-right: 20px;
      opacity: 0.5;
    }
  }

  .nav-m-section-title {
    display: flex;
    font-family: $secondary-fontfamily;
    color: $accent;
    margin-bottom: 20px;
    position: relative;

    &:hover {
      color: $accent-light;
      text-decoration: underline;
    }

    &.nav-m-section-title-space {
      margin-top: 40px;
    }

    &.nav-m-section-title-no-margin-bottom {
      margin-bottom: 0;
    }

    .nav-m-section-title-icon {
      font-size: 18px;
      margin-left: 8px;
      position: relative;
      top: 8px;
    }
  }

  .nav-m-section-title-nolink {
    display: flex;
    font-family: $secondary-fontfamily;
    color: $accent;
    margin-bottom: 20px;
    position: relative;
  }

  .nav-m-section-technology {
    display: flex;
    font-family: $secondary-fontfamily;
    color: $white;

    font-size: 1.8rem;
    margin-top: 20px;

    &:hover {
      color: $grey-light;
    }
  }

  .nav-m-section-button {
    position: relative;
    display: inline-block;
    margin-top: 20px;
    padding: 3px 36px;
    background-color: $accent;
    border-radius: 3px;
    color: $font-primary;
    box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.2);
    text-decoration: none;

    &:hover {
      color: $font-primary;
      background-color: $accent-light;
      text-decoration: none;
    }
  }

  .nav-m-section-text {
    display: block;
    color: white;
    font-size: 1.4rem;
    font-weight: normal;
  }

  .nav-m-section-basf-logo {
    width: 150px;
  }
}
