.flexbox-table {
  display: block;
  position: relative;
}

.flexbox-row {
  display: flex;
  flex-flow: row nowrap;

  @media #{$medium-and-down} {
    display: block;
    margin-bottom: 10px;
    padding: 10px 0;
  }
}

.flexbox-row--space-between {
  justify-content: space-between;
}

.flexbox-row--align-item-center {
  align-items: center;
}

%row-common-background {
  background-color: $white;
  border-radius: 10px;
}

.flexbox-row--background-even {
  @extend %row-common-background;

  &:nth-child(even) {
    background-color: $table-row-even;
  }
}

.flexbox-row--background-odd {
  @extend %row-common-background;

  &:nth-child(odd) {
    background-color: $table-row-even;
  }
}

.flexbox-row-cell {
  flex: 1;
  text-align: left;

  //avoid issue with the text that overflow even with overflow:hidden set on the parent
  //https://stackoverflow.com/questions/12022288/how-to-keep-a-flex-item-from-overflowing-due-to-its-text
  min-width: 1px;

  padding: 10px;

  &.flexbox-item--grow-2 {
    flex-grow: 2;
  }
  &.flexbox-item--grow-3 {
    flex-grow: 3;
  }
  &.flexbox-item--grow-4 {
    flex-grow: 4;
  }
  &.flexbox-item--grow-5 {
    flex-grow: 5;
  }
  &.flexbox-item--grow-6 {
    flex-grow: 6;
  }
  &.flexbox-item--grow-7 {
    flex-grow: 7;
  }
  &.flexbox-item--grow-8 {
    flex-grow: 8;
  }
  &.flexbox-item--grow-9 {
    flex-grow: 9;
  }
  &.flexbox-item--grow-10 {
    flex-grow: 10;
  }

  &.flexbox-item--basis-auto {
    flex-basis: auto;
  }

  &.flexbox-item--vertical {
    &:first-child {
      padding-top: 0;
    }
    @media #{$medium-and-down} {
      padding-left: 0;
    }
  }

  @media #{$medium-and-down} {
    width: 100%;
    flex: none;
    min-height: 0;
    margin-bottom: 10px;
    //line-height: 20px;
    padding: 0 10px;

    div[data-title] {
      display: inline-block;

      width: 100%;

      &:before {
        content: attr(data-title);
        display: inline-block;
        width: 30%;
        color: $grey;
        margin-top: 0;
        font-weight: 400;
        letter-spacing: 0;
        vertical-align: top;
      }
    }
  }

  @media #{$small-and-down} {
    div[data-title] {
      &:before {
        width: 40%;
      }
    }
  }

  @media only screen and (max-width: 400px) {
    div[data-title] {
      &:before {
        width: 100%;
      }
    }
  }

  .flexbox-row-cell-icon {
    margin-left: 5px;
    position: relative;
    top: 2px;
    font-size: 1.6rem;
  }

  .flexbox-row-cell-list {
    display: block;

    @media #{$medium-and-down} {
      display: inline-block;
    }

    @media only screen and (max-width: 400px) {
      display: block;
    }

    li:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .text-link {
    &:hover {
      span:not(.flexbox-row-cell-icon) {
        text-decoration: underline;
      }

      .flexbox-row-cell-icon {
        color: $primary-blue;
      }
    }
  }
}

.flexbox-row-cell--aright {
  text-align: right;

  //on mobile we want everything to be align to the left
  @media #{$medium-and-down} {
    text-align: left;
  }
}

.flexbox-row-cell--acenter {
  text-align: center;

  //on mobile we want everything to be align to the left
  @media #{$medium-and-down} {
    text-align: left;
  }
}

.flexbox-row-cell--padding-right {
  div[data-title] {
    padding-right: 10px;
  }

  //use for the reorder button
  div .btn {
    margin-right: 10px;
  }
}

.flexbox-row-cell--selectable {
  @media #{$large-and-up} {
    div[data-title] {
      display: block;
      min-width: 100%;
    }
  }
}

.flexbox-table-header {
  background-color: $grey-lightest;
  color: $font-secondary;
  // border-bottom: 2px solid $grey-lighter;
  box-shadow: 0 3px 1px -1px rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;

  font-family: $secondary-fontfamily;
  font-size: 2rem;
  font-weight: bold;

  @media #{$medium-and-down} {
    display: none;
  }

  .filter-active {
    position: relative;

    color: $font-primary;

    span {
      position: relative;
      display: inline-block;
      margin-left: 10px;
      top: 2px;
      color: $font-primary;
      font-size: 1.4rem;
    }
  }
}

.flexbox-table-contents {
  &.disabled {
    min-height: 100px;
    position: relative;
    .flexbox-row-cell,
    .flexbox-row-cell a {
      color: $grey;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(255, 255, 255, 0);
      user-select: none;
      z-index: 99;
    }
  }
}
