// base rules
// ==========================================================================

html {
  font-size: $base-font-size;
}

body {
  font-family: $primary-fontfamily;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  color: $font-primary;
  font-size: 13px; // delete it after all the css have been moving into sass file
  text-align: center;

  &.lock-position {
    position: fixed;
    overflow: hidden;
  }
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}

// Sculpteo3DViewer
// ==========================================================================
.Sculpteo3DViewer {
  /* Direct all pointer events to JavaScript code. */
  -ms-touch-action: double-tap-zoom;
}
