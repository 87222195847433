//modal with background shadow

.modal,
.sculpteo-modal {
  // use sculpteo-modal to avoid clashes with other libs
  position: relative;
  width: 100%;
  z-index: 10001;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.35);
  border-top: 4px solid $primary-blue;
  background-color: $white;
  padding: $gutter-width;
}

.modal--fixed-top {
  position: fixed;
  top: 70px;
  max-width: 700px;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
}

.with_promo_banner .modal--fixed-top {
  top: 90px;
}

.modal--center {
  position: fixed;
  top: 50%;
  max-width: 700px;
  width: 80%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

#new-cookies .modal--center {
  max-width: 550px;
}

.modal--responsive {
  max-height: 85%;
  overflow: auto;

  @media #{$medium-and-down} {
    width: 95%;
    height: 85%;
  }
}

.modal--scroll-on-desktop {
  max-height: 80%;
  overflow: auto;
}

.modal-container {
  position: relative;
  text-align: center;

  .top-right-close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: $primary-blue;
    font-weight: bold;
  }

  .btn.btn-secondary {
    background-color: white;
    color: $primary-blue;
    border: 1px solid $primary-blue;

    &:hover {
      color: $primary-blue !important;
    }
  }
}

.modal-background {
  background: rgba(0, 0, 0, 0.35);
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
}

.modal-back.modal-back-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
  color: $grey;
  font-size: 2rem;

  &:hover {
    color: $font-primary;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

//modal IN page, no shadow
//used for page reset password, login, sign up
.page-container-modal {
  position: relative;
  text-align: center;

  background-color: white;
  border: 1px solid $grey-lighter;
  border-radius: 5px;
  max-width: 510px;
  margin: 0 auto;
  padding-left: $gutter-width;
  padding-right: $gutter-width;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.page-container-modal--margin-top {
  margin-top: 1rem;
}

.black-box-modal {
  border: 2px solid rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  padding: 0;
  box-shadow: none;
  max-width: 900px;
}
.modal-x {
  position: absolute;
  top: 4px;
  right: 17px;
  font-size: 32px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.79);
  font-family: Open Sans;
  font-weight: 400;
  span:hover {
    color: rgba(255, 255, 255, 1);
  }
}
