$login-container-width: 550px;
$popin_z_index: 999;

.popin_wrapper {
  display: none;
  position: fixed;
  z-index: $popin_z_index;

  // this makes it always vertically and horizontally centered on the screen
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  width: 80%; // this associated to max-width allows it scale down on narrow screen
  max-width: $login-container-width; // limit width on wide enough screens

  max-height: 95%; // this allows to scale it down vertically on small screens
  overflow: auto; // allow vertical scrolling if needed

  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.35);

  @media #{$small-and-down} {
    border-radius: 0;
    width: 97%; // on very narrow screen we take full width
  }
}

.popin_background {
  display: none;
  position: fixed;
  z-index: $popin_z_index - 1;
  background: rgba(0, 0, 0, 0.35);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popin_container {
  position: relative;
  width: 100%;
  z-index: $popin_z_index;
  border-top: 4px solid $primary-blue;
  background-color: white;
  padding: 0 15px 0 15px;
  border-radius: 3px;
}

.popin_container-login,
.popin_container-signup {
  display: none;

  // we only use one .row in those modals
  // set to zero otherwise would overflow a transparent block below the modal
  .row {
    margin-bottom: 0px;
  }
}

// this container used for login and signup pages
// where user lands if they click on login / signup
// on a small screen
.page_container {
  background-color: white;
  border: 1px solid $grey-lighter;
  border-radius: 5px;
  position: relative;
  max-width: $login-container-width;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 25px;
}

.modal_login-signup {
  position: relative;
  text-align: center;
}

.modal_login-signup *,
.modal_login-signup *:before,
.modal_login-signup *:after {
  box-sizing: border-box;
}

.modal_login-signup h1,
.modal_login-signup .h1 {
  font-size: 32px;
  padding-top: 10px;
  padding-bottom: 20px;
  line-height: 43px;
  margin: 0;
  font-weight: 300;
}

.modal_login-signup .information {
  padding-bottom: 25px;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.modal_login-signup .forms {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.modal_login-signup .forms label {
  text-align: left;
}
.modal_login-signup .forms label:last-of-type {
  margin-bottom: 0;
}

.modal_login-signup .forms .hidden,
.modal_login-signup .forms .hidden:after,
.modal_login-signup .forms .hidden:before {
  content: "";
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.modal_login-signup .forms input {
  height: 35px;
}

.modal_login-signup .forms .checkbox {
  text-align: left;
  margin-top: 15px;
}

.modal_login-signup .forms [type="checkbox"] + label:before,
.modal_login-signup .forms [type="checkbox"]:checked + label:before {
  border-color: $primary-blue;
}

.modal_login-signup .forms .checkbox label {
  font-size: 13px;
  color: $primary-blue;
}

.modal_login-signup .forms .field-icon {
  padding-top: 8px;
}

.modal_login-signup .forms .submit-container {
  position: relative;
  max-width: 280px;
  margin: 20px auto 0px auto;
}

.modal_login-signup .forms .submit-container.waiting:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 20px;
  left: -25px;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-left: -12px;
  border-radius: 50%;
  border: 2px solid $primary-blue;
  border-top-color: #fff;
  animation: spinner 0.6s linear infinite;
}

.modal_login-signup .forms .submit-container .submit_button {
  position: relative;
  display: block;
  padding: 6px 36px;
  background-color: $primary;
  border-radius: 3px;
  color: $white;
  box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  text-transform: none;
  font-family: $secondary-fontfamily;
  font-size: 2.4rem;
  font-weight: bold;
  height: auto;
  margin: 0;
  cursor: pointer;

  &:hover {
    color: $white;
    background-color: $primary-light;
    text-decoration: none;
  }
}

.modal_login-signup .forms .submit-container.waiting .submit_button,
.modal_login-signup .forms .submit-container.waiting .submit_button:hover {
  background-color: $font-disabled;
  color: $white;
  cursor: default;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.modal_login-signup .forms .forgot_password {
  text-align: right;
  font-size: 12px;
}

.modal_login-signup .create_account {
  font-size: 13px;
  padding-bottom: 20px;
}

.modal_login-signup .privacy_policy {
  font-size: 12px;
  padding-top: 70px;
  width: 95%;
  margin: 0 auto;
  color: #7c7c7c;
}

.modal_login-signup .or_separator {
  overflow: hidden;
  margin: 17px auto;
  margin-bottom: 10px;
  width: 65%;
}

.modal_login-signup .or_separator .text {
  display: block;
  font-size: 16px;
  color: #49e;
  float: left;
  width: 14%;
}

.modal_login-signup .or_separator .line {
  display: block;
  position: relative;
  top: 10px;
  float: left;
  width: 43%;
  height: 1px;
  background: #49e;
}

.modal_login-signup .social_button {
  font-size: 13px;
  text-align: left;
  height: auto;
  display: block;
  width: 100%;
  max-width: 220px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 400;
  margin: 0 auto;
  text-shadow: none;
  background-color: transparent;
  border: 1px solid $primary-blue;
  color: $primary-blue;
  border-radius: 2px;
  transition: all 0.2s;
  cursor: pointer;
  position: relative;
  padding-left: 40px;
}

.modal_login-signup .social_button img {
  position: absolute;
  left: 10px;
}

.modal_login-signup .social_button:hover {
  border-radius: 10px;
  text-decoration: none;
}

.modal_login-signup .notice {
  font-size: 13px;
  position: relative;

  margin-top: 25px;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .page_container {
    border: 1px solid transparent;
    border-radius: 0;
  }
}

@media (max-width: 600px) {
  .modal_login-signup .privacy_policy {
    width: 100%;
  }

  .modal_login-signup .separator {
    width: 80%;
  }

  .modal_login-signup .or_separator {
    width: 80%;
  }

  .modal_login-signup .social_button:first-child {
    margin-bottom: 15px;
  }
}

@media (max-width: 400px) {
  .modal_login-signup .separator {
    width: 100%;
  }

  .modal_login-signup .or_separator {
    width: 100%;
  }
}
