.gift-card {
  position: relative;
  font-size: 0;

  margin-bottom: 20px;
  background-color: $white;
}

.gift-card-background {
  position: relative;
  width: 50%;
  overflow: hidden;
  display: inline-block;

  @media #{$small-and-down} {
    width: 40%;
  }

  .gift-card-mask {
    clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);

    @media #{$extra-large} {
      clip-path: polygon(0 0, 55% 0, 100% 100%, 0% 100%);
    }

    @media #{$small-and-down} {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }

    img {
      transform: rotate(180deg);
      @media #{$small-and-down} {
        height: 220px;
      }
    }
  }

  .gift-card-value {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    @media #{$small-and-down} {
      left: 50%;
      transform: translate(-50%, -50%);
    }

    p {
      font-size: 6.5rem;
      font-weight: 700;
      margin-bottom: 0;
      color: $white;

      @media #{$extra-large} {
        font-size: 9rem;
      }

      @media #{$small-and-down} {
        font-size: 5rem;
      }
    }
  }
}

.gift-card-content {
  width: 50%;
  display: inline-block;
  transform: translate(-10%, 50%);
  vertical-align: top;

  @media #{$small-and-down} {
    width: 60%;
    transform: translate(0%, 30%);
  }

  h3 {
    padding-bottom: 5px;
    font-weight: 400;
  }

  a {
    margin-top: 20px;
    font-weight: normal;
  }
}
